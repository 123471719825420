import moment from "moment";
import { FC, useEffect } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useAppDispatch } from "../../../app/hooks";
import { useAccount, useMsal } from "@azure/msal-react";
import { getUserOrders } from "../../../app/services/orderService";
import { Order } from "../../../models/responses/order";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import { Check, Eye } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

interface Props { }

const Dashboard: FC<Props> = () => {
  moment.locale('sv');
  moment.updateLocale(moment.locale(), { invalidDate: "-" })
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const orders: Order[] = useSelector(
    (state: RootState) => state.order.ordersByUserId
  );
  const loadingOrders: boolean = useSelector(
    (state: RootState) => state.order.isWaitingOrderGetResponse
  );

  useEffect(() => {
    dispatch(getUserOrders(instance, account));
  }, [account, accounts, dispatch, instance]);

  const openOrderHandler = (order: Order) => {
    navigate(
        `/orderStatus/${order.orderStatusId}/orders/view/${order.id}`,
        { relative: "path" }
      );
  };

  return (
    <div>
      <h1 className="mb-3">Aktiva ordrar</h1>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Status</th>
            <th>Kund</th>
            <th>Inkommen</th>
            <th>Reg.nr</th>
            <th>AO-nummer</th>
            <th>Redigeras av</th>
            <th>Exporterad</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id} role="button" onClick={() => openOrderHandler(order)}>
              <td>{order.orderStatus?.name}</td>
              <td>{order.customer?.name}</td>
              <td>{moment(order.arriveDate).format("LL")}</td>
              <td>{order.registrationNumber}</td>
              <td>{order.aoNumber}</td>
              <td>{order.userName}</td>
              <td>
                {order.exported && (
                  <Check className="text-success" />
                )}
              </td>
              <td align="center">
                <Eye />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {loadingOrders && (
        <>
          <Row>
            <Col className="text-center">
              <Spinner variant="primary" />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Dashboard;
