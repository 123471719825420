import { ArticleStatus } from "./articleStatus";
import { Supplier } from "./supplier";

export interface Article {
  id: string | null;
  verified: boolean;
  externalId: string;
  articleNumber: string;
  description: string;
  purchaseOrderComment: string;
  salesOrderComment: string;
  internalComment: string;
  externalArticleCode: string;
  replacement: string;
  quantity: number;
  rowNumber: number;
  purchasePrice: number;
  salesPrice: number;
  vat: number;
  supplierId: string | null;
  supplier: Supplier | null;
  articleStatusId: string | null;
  articleStatus: ArticleStatus | null;
  totalExVat: number;
  totalIncVat: number;
}

export const defaultArticle: Article =
{
  id: null,
  verified: false,
  externalId: "",
  articleNumber: "",
  description: "",
  purchaseOrderComment: "",
  salesOrderComment: "",
  internalComment: "",
  externalArticleCode: "",
  replacement: "",
  quantity: 0,
  rowNumber: 0,
  purchasePrice: 0,
  salesPrice: 0,
  vat: 0,
  supplierId: null,
  supplier: null,
  articleStatusId: null,
  articleStatus: null,
  totalExVat: 0,
  totalIncVat: 0
};
