import axios from "axios";
import { AppDispatch } from "../store";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import {
  setOrder,
  setOrderList,
  setIsWaitingOrderPostResponse,
  setIsWaitingOrderPutResponse,
  setOrderUser,
  setOrderListByUserId,
  setIsWaitingOrderGetResponse,
  setQueryOrderList,
} from "../slices/orderSlice";
import { getApiUrl } from "../helpers/environmentHelper";
import { Order } from "../../models/responses/order";
import { OrderUser } from "../../models/responses/orderUser";
import { QueryOrder } from "../../models/responses/queryOrder";
import { QueryOrderRequest } from "../../models/requests/queryOrderRequest";

export const getOrders =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    statusId: string | undefined
  ) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingOrderGetResponse(true));

    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            console.log(response.accessToken);
            if (response) {
              const getData: Order[] = (
                await axios.get(`${baseUrl}/orders/status/${statusId}`, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;
              dispatch(setIsWaitingOrderGetResponse(false));
              dispatch(setOrderList({ orders: getData }));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingOrderGetResponse(false));
      console.log(error);
    }
  };

export const queryOrders =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    statusId: string | undefined,
    page: number,
    pageSize: number
  ) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingOrderGetResponse(true));
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              const query: QueryOrderRequest = { page, pageSize, statusId };
              const getData: QueryOrder = (
                await axios.post(`${baseUrl}/orders/query`, query, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;
              console.log(getData);
              dispatch(setQueryOrderList({ query: getData }));
              dispatch(setIsWaitingOrderGetResponse(false));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingOrderGetResponse(false));
      console.log(error);
    }
  };

export const getOrderById =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    id: string | undefined
  ) =>
  async (dispatch: AppDispatch) => {
    if (!id) return;

    dispatch(setIsWaitingOrderGetResponse(true));
    const baseUrl = getApiUrl(window.location.hostname, 1);

    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              const getData: Order = (
                await axios.get(`${baseUrl}/orders/${id}`, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;
              dispatch(setIsWaitingOrderGetResponse(false));
              dispatch(setOrder({ order: getData }));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingOrderGetResponse(false));
      console.log(error);
    }
  };

export const postOrder =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    order: Order,
    onSuccess: (orderId: string | null) => void
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(setIsWaitingOrderPostResponse(true));
    order = handleOrderForRequest(order);

    const baseUrl = getApiUrl(window.location.hostname, 1);
    let responseId: string | null = null;
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            try {
              if (response) {
                const responseData = await axios.post(
                  `${baseUrl}/orders`,
                  order,
                  {
                    timeout: 10000,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${response.accessToken}`,
                    },
                  }
                );

                if (responseData.status === 201) {
                  let locationArray =
                    responseData.headers["location"].split("/");
                  responseId = locationArray[locationArray.length - 1];
                }
              }
            } finally {
              onSuccess(responseId);
              dispatch(setIsWaitingOrderPostResponse(false));
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const putOrder =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    order: Order,
    onSuccess: (orderId: string | null) => void
  ) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingOrderPutResponse(true));
    order = handleOrderForRequest(order);
    
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            try {
              if (response) {
                const responseData = await axios.put(
                  `${baseUrl}/orders`,
                  order,
                  {
                    timeout: 10000,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${response.accessToken}`,
                    },
                  }
                );
                if (responseData.status === 204) {
                  onSuccess(order.id);
                }
              }
            } finally {
              dispatch(setIsWaitingOrderPutResponse(false));
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const assignOrder =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    id: string | undefined
  ) =>
  async () => {
    if (!id) return;
    const baseUrl = getApiUrl(window.location.hostname, 1);
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              await axios.patch(
                `${baseUrl}/orders/${id}/assign`,
                {},
                {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                }
              );
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const removeAssignOrder =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    id: string | undefined
  ) =>
  async () => {
    if (!id) return;
    const baseUrl = getApiUrl(window.location.hostname, 1);
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              await axios.patch(
                `${baseUrl}/orders/${id}/remove-assign`,
                {},
                {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                }
              );
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getOrderUserById =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    id: string | undefined
  ) =>
  async (dispatch: AppDispatch) => {
    if (!id) return;

    dispatch(setIsWaitingOrderGetResponse(true));
    const baseUrl = getApiUrl(window.location.hostname, 1);

    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            try {
              if (response) {
                const getData: OrderUser = (
                  await axios.get(`${baseUrl}/orders/${id}/user`, {
                    timeout: 10000,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${response.accessToken}`,
                    },
                  })
                ).data;
                dispatch(setIsWaitingOrderGetResponse(false));
                dispatch(setOrderUser({ orderUser: getData }));
              }
            } catch {
              dispatch(setIsWaitingOrderGetResponse(false));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingOrderGetResponse(false));
      console.log(error);
    }
  };

export const getUserOrders =
  (instance: IPublicClientApplication, account: AccountInfo | null) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingOrderGetResponse(true));

    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            console.log(response.accessToken);
            if (response) {
              const getData: Order[] = (
                await axios.get(`${baseUrl}/orders/get-assigned-orders`, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;
              dispatch(setOrderListByUserId({ orders: getData }));
              dispatch(setIsWaitingOrderGetResponse(false));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingOrderGetResponse(false));
      console.log(error);
    }
  };

export const exportOrder =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    id: string | undefined,
    onSuccess: (orderId: string | null) => void
  ) =>
  async () => {
    if (!id) return;
    const baseUrl = getApiUrl(window.location.hostname, 1);
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              const result = await axios.patch(
                `${baseUrl}/orders/${id}/export`,
                {},
                {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                }
              );
              if (result.status === 204) {
                onSuccess(id);
              }
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrderForRequest = (order: Order) : Order => {
    if(!order.articles)
      return order;

    order.articles = order.articles.map(article => ({
      ...article,
      quantity: handleFloatToInt(article.quantity),
      purchasePrice: handleFloatToInt(article.purchasePrice),
      salesPrice: handleFloatToInt(article.salesPrice),
      vat: handleFloatToInt(article.vat),
     }));

     return order;
  }

  const handleFloatToInt = (value: number) : number => {
    return parseInt(value.toFixed(2).replace('.', ''));    
  }