import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postOrder } from "../../app/services/orderService";
import { useAppDispatch } from "../../app/hooks";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Form from "react-bootstrap/Form";
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Container,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { defaultOrder, Order } from "../../models/responses/order";
import { OrderStatus } from "../../models/responses/orderStatus";
import {
  getOrderStatusById,
  getOrderStatuses,
} from "../../app/services/orderStatusService";
import { Customer } from "../../models/responses/customer";
import { getCustomers } from "../../app/services/customerService";
import { OrderType } from "../../models/responses/orderType";
import { getOrderTypes } from "../../app/services/orderTypeService";
import { Supplier } from "../../models/responses/supplier";
import { getSuppliers } from "../../app/services/supplierService";
import { Article, defaultArticle } from "../../models/responses/article";
import { ArticleStatus } from "../../models/responses/articleStatus";
import { getArticleStatuses } from "../../app/services/articleStatusService";
import {
  Check,
  CheckAll,
  ExclamationTriangle,
  Pen,
  Plus,
  X,
} from "react-bootstrap-icons";

interface Props { }

const OrderAdd: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [displayError, setDisplayError] = useState(false);
  const { orderStatusId } = useParams<{ orderStatusId: string }>();
  const [orderData, setOrderData] = useState<Order>({ ...defaultOrder });
  const [showArticleModal, setShowArticleModal] = useState(false);
  const [modalArticle, setModalArticle] = useState<Article>({
    ...defaultArticle,
  });
  const [allVerified, setAllVerified] = useState(false);

  const orderStatus: OrderStatus = useSelector(
    (state: RootState) => state.orderStatus.orderStatus
  );
  const orderStatusList: OrderStatus[] = useSelector(
    (state: RootState) => state.orderStatus.setOrderStatusList
  );
  const customers: Customer[] = useSelector(
    (state: RootState) => state.customer.customerList
  );
  const orderTypes: OrderType[] = useSelector(
    (state: RootState) => state.orderTypes.orderTypeList
  );
  const suppliers: Supplier[] = useSelector(
    (state: RootState) => state.supplier.supplierList
  );
  const articleStatuses: ArticleStatus[] = useSelector(
    (state: RootState) => state.articleStatus.articleStatusList
  );
  const isWaitingOrderPostResponse = useSelector((state: RootState) => state.order.isWaitingOrderPostResponse);
  useEffect(() => {
    dispatch(getOrderStatusById(instance, account, orderStatusId));
  }, [account, accounts, dispatch, instance, orderStatusId]);

  useEffect(() => {
    dispatch(getOrderStatuses(instance, account));
  }, [account, accounts, dispatch, instance]);

  useEffect(() => {
    dispatch(getArticleStatuses(instance, account));
  }, [account, accounts, dispatch, instance]);

  useEffect(() => {
    dispatch(getCustomers(instance, account));
  }, [account, accounts, dispatch, instance]);

  useEffect(() => {
    if (customers.length > 0) {
      orderData.customerId = customers[0].id;
    }
  }, [customers]);

  useEffect(() => {
    if (orderStatusList.length > 0) {
      orderData.orderStatusId = orderStatusList[0].id;
    }
  }, [orderStatusList]);

  useEffect(() => {
    if (orderTypes.length > 0) {
      orderData.orderTypeId = orderTypes[0].id;
    }
  }, [orderTypes]);

  useEffect(() => {
    dispatch(getOrderTypes(instance, account));
  }, [account, accounts, dispatch, instance]);

  useEffect(() => {
    dispatch(getSuppliers(instance, account));
  }, [account, accounts, dispatch, instance]);

  useEffect(() => {
    checkVerified();
  }, [orderData.articles]);

  useEffect(() => {
    setModalArticle({
      ...modalArticle,
      totalExVat: parseFloat(
        (modalArticle.salesPrice * modalArticle.quantity).toFixed(2)
      ),
      totalIncVat: parseFloat(
        (
          modalArticle.salesPrice *
          modalArticle.quantity *
          (1 + modalArticle.vat / 100)
        ).toFixed(2)
      ),
    });
  }, [modalArticle.quantity, modalArticle.salesPrice, modalArticle.vat]);

  const customerInputHandler = (e: string) => {
    setOrderData({ ...orderData, customerId: e });
  };
  const assignedToInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, assignedTo: e.target.value });
  };
  const registrationNumberInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderData({ ...orderData, registrationNumber: e.target.value });
  };
  const vinInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, vin: e.target.value });
  };
  const carInfoInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, carInfo: e.target.value });
  };
  const aoNumberInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, aoNumber: e.target.value });
  };
  const purchaseOrderNumberInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderData({ ...orderData, purchaseOrderNumber: e.target.value });
  };
  const salesOrderNumberInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderData({ ...orderData, salesOrderNumber: e.target.value });
  };
  const orderTypeInputHandler = (e: string) => {
    setOrderData({ ...orderData, orderTypeId: e });
  };
  const orderedByInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, orderedBy: e.target.value });
  };
  const followUpInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, followUp: e.target.value });
  };
  const emailBodyInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, emailBody: e.target.value });
  };
  const warehouseLocationInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderData({ ...orderData, warehouseLocation: e.target.value });
  };
  const supplierInputHandler = (e: string) => {
    setOrderData({ ...orderData, supplierId: e });
  };
  const arriveDateInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, arriveDate: e.target.value });
  };
  const bookingDateInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, bookingDate: e.target.value });
  };
  const deliveryDateInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, deliveryDate: e.target.value });
  };
  const pickupDateInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, pickupDate: e.target.value });
  };
  const orderDateInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, orderDate: e.target.value });
  };
  const commentInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, comment: e.target.value });
  };
  const internalCommentInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderData({ ...orderData, internalComment: e.target.value });
  };
  const orderStatusInputHandler = (e: string) => {
    setOrderData({ ...orderData, orderStatusId: e });
  };

  // ARTICLES // -----------------------------
  const articleArticleNumberInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, articleNumber: e.target.value });
  };
  const articleReplacementInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, replacement: e.target.value });
  };
  const articleQuantityInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, quantity: parseInt(e.target.value) });
  };
  const articleExternalIdInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, externalId: e.target.value });
  };
  const articleExternalArticleCodeInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, externalArticleCode: e.target.value });
  };
  const articleDescriptionInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, description: e.target.value });
  };
  const articlePurchaseOrderCommentInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, purchaseOrderComment: e.target.value });
  };
  const articleSalesOrderCommentInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, salesOrderComment: e.target.value });
  };
  const articleInternalCommentInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, internalComment: e.target.value });
  };
  const articlePurchasePriceInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({
      ...modalArticle,
      purchasePrice: parseInt(e.target.value),
    });
  };
  const articleSalesPriceInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({
      ...modalArticle,
      salesPrice: parseInt(e.target.value),
    });
  };
  const articleVatInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModalArticle({ ...modalArticle, vat: parseInt(e.target.value) });
  };
  const articleSupplierInputHandler = (e: string) => {
    setModalArticle({ ...modalArticle, supplierId: e, supplier: suppliers.find((s) => s.id === e) || null });
  };
  const articleArticleStatusInputHandler = (e: string) => {
    setModalArticle({ ...modalArticle, articleStatusId: e });
  };

  const saveArticleInModalHandler = () => {
    const savedArticles = orderData.articles?.map<Article>((a) => {
      if (a.rowNumber === modalArticle.rowNumber) return modalArticle;
      return a;
    });

    if (savedArticles) {
      setOrderData({ ...orderData, articles: savedArticles });
    }

    setShowArticleModal(false);
  };

  const validatedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModalArticle({ ...modalArticle, verified: e.target.checked });
  };

  const removeArticleHandler = () => {
    const keptArticles = orderData.articles?.filter((a) => {
      return a.rowNumber !== modalArticle.rowNumber;
    });

    if (keptArticles) {
      setOrderData({ ...orderData, articles: keptArticles });
    }

    setShowArticleModal(false);
  };

  const addNewArticleHandler = () => {
    let newArticle: Article = { ...defaultArticle };
    newArticle.rowNumber = calculateRowNumber();
    newArticle.articleStatusId = articleStatuses[0].id;

    if (orderData.articles === null) orderData.articles = [];

    let newList = [...orderData.articles, newArticle];

    setOrderData({
      ...orderData,
      articles: newList,
    });

    setModalArticle(newArticle);
    setShowArticleModal(true);
  };

  const calculateRowNumber = () => {
    if (!orderData.articles) return 0;

    return (Math.max(...orderData.articles!.map((a) => a.rowNumber)) ?? 0) + 1;
  };

  // ARTICLES END // -----------------------------

  const checkVerified = () => {
    let allVerified = orderData.articles?.every((art) => {
      if (art.verified) return true;
      return false;
    });

    if (allVerified) {
      setAllVerified(true);
    } else {
      setAllVerified(false);
    }
  };

  const handleAddOrder = () => {
    setDisplayError(false);
    dispatch(postOrder(instance, account, orderData, onSuccess));
  };

  const openArticleModalHandler = (article: Article) => {
    console.log(article);
    setModalArticle({ ...modalArticle, ...article });
    setShowArticleModal(true);
  };

  const closeArticleModalHandler = () => {
    setShowArticleModal(false);
  };

  const onSuccess = (orderId: string | null) => {
    if (orderId === null) {
      setDisplayError(true);
      return;
    }

    setTimeout(() => {
      navigate(`../view/${orderId}`, { relative: "path" });
    }, 500);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href={`/orderStatus/${orderStatus.id}`}>
          {orderStatus.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Ny order</Breadcrumb.Item>
      </Breadcrumb>
      <hr />
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Select
                aria-label="Status"
                value={orderData.orderStatusId || ""}
                onChange={(e) => orderStatusInputHandler(e.target.value)}
              >
                {orderStatusList.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Kund</Form.Label>
              <Form.Select
                aria-label="Kund"
                value={orderData.customerId || ""}
                onChange={(e) => customerInputHandler(e.target.value)}
              >
                {customers.map((customer) => (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Ansvarig</Form.Label>
              <Form.Control
                type="text"
                value={orderData.assignedTo ?? ""}
                onInput={assignedToInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Registreringsnummer</Form.Label>
              <Form.Control
                type="text"
                value={orderData.registrationNumber ?? ""}
                onInput={registrationNumberInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>VIN</Form.Label>
              <Form.Control
                type="text"
                value={orderData.vin ?? ""}
                onInput={vinInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Bilmärke</Form.Label>
              <Form.Control
                type="text"
                value={orderData.carInfo ?? ""}
                onInput={carInfoInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>AO-nummer</Form.Label>
              <Form.Control
                type="text"
                value={orderData.aoNumber ?? ""}
                onInput={aoNumberInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>FO-nummer</Form.Label>
              <Form.Control
                type="text"
                value={orderData.purchaseOrderNumber ?? ""}
                onInput={purchaseOrderNumberInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>IO-nummer</Form.Label>
              <Form.Control
                type="text"
                value={orderData.salesOrderNumber ?? ""}
                onInput={salesOrderNumberInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Ordertyp</Form.Label>
              <Form.Select
                aria-label="Ordertyp"
                value={orderData.orderTypeId || ""}
                onChange={(e) => orderTypeInputHandler(e.target.value)}
              >
                {orderTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Beställd av</Form.Label>
              <Form.Control
                type="text"
                value={orderData.orderedBy ?? ""}
                onInput={orderedByInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Uppföljning</Form.Label>
              <Form.Control
                type="text"
                value={orderData.followUp ?? ""}
                onInput={followUpInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>E-post innehåll</Form.Label>
              <Form.Control
                type="text"
                value={orderData.emailBody ?? ""}
                onInput={emailBodyInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Lagerplats</Form.Label>
              <Form.Control
                type="text"
                value={orderData.warehouseLocation ?? ""}
                onInput={warehouseLocationInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Leverantör</Form.Label>
              <Form.Select
                aria-label="Leverantör"
                value={orderData.supplierId || ""}
                onChange={(e) => supplierInputHandler(e.target.value)}
              >
                <option key={0} value={undefined}>
                  - Ingen leverantör vald -
                </option>
                {suppliers.map((supplier) => (
                  <option key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Inkommen</Form.Label>
              <Form.Control
                type="date"
                value={orderData.arriveDate ?? ""}
                onInput={arriveDateInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Bokad (prod.dag)</Form.Label>
              <Form.Control
                type="date"
                value={orderData.bookingDate ?? ""}
                onInput={bookingDateInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Leveransdatum</Form.Label>
              <Form.Control
                type="date"
                value={orderData.deliveryDate ?? ""}
                onInput={deliveryDateInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Upphämtningsdatum</Form.Label>
              <Form.Control
                type="date"
                value={orderData.pickupDate ?? ""}
                onInput={pickupDateInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Beställt</Form.Label>
              <Form.Control
                type="date"
                value={orderData.orderDate ?? ""}
                onInput={orderDateInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Kommentar</Form.Label>
              <Form.Control
                value={orderData.comment ?? ""}
                as="textarea"
                onInput={commentInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Intern kommentar</Form.Label>
              <Form.Control
                as="textarea"
                value={orderData.internalComment ?? ""}
                onInput={internalCommentInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Table responsive striped bordered hover>
              <thead>
                <tr key="-1">
                  <th>
                    {allVerified ? (
                      <CheckAll className="text-success" />
                    ) : (
                      <ExclamationTriangle className="text-danger" />
                    )}
                  </th>
                  <th>Antal</th>
                  <th>Beskrivning</th>
                  <th>Art.nr</th>
                  <th>Inköpspris</th>
                  <th>Försäljningspris</th>
                  <th>Leverantör</th>
                  <th>
                    <Pen />
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderData?.articles?.map((article) => (
                  <tr
                    key={article.rowNumber}
                    onClick={() => openArticleModalHandler(article)}
                  >
                    <td>
                      {!article.verified ? (
                        <X className="text-danger" />
                      ) : (
                        <Check className="text-success" />
                      )}
                    </td>
                    <td>{article.quantity}</td>
                    <td>{article.description}</td>
                    <td>{article.articleNumber}</td>
                    <td>{article.purchasePrice}</td>
                    <td>{article.salesPrice}</td>
                    <td>{article.supplier?.name}</td>
                    <td>
                      <Pen />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Modal
          size="xl"
          show={showArticleModal}
          onHide={() => closeArticleModalHandler()}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {modalArticle.articleNumber
                ? `${modalArticle.articleNumber} - ${modalArticle.description}`
                : "Ny artikel"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col xs={12} md={1}>
                  <Form.Group className="mb-3">
                    <Form.Label>Antal</Form.Label>
                    <Form.Control
                      type="number"
                      defaultValue={modalArticle.quantity ?? ""}
                      onInput={articleQuantityInputHandler}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={5}>
                  <Form.Group className="mb-3">
                    <Form.Label>Artikelnummer</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.articleNumber ?? ""}
                      onInput={articleArticleNumberInputHandler}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Ersätter</Form.Label>
                    <Form.Control
                      type="text"
                      onInput={articleReplacementInputHandler}
                      defaultValue={modalArticle.replacement}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {modalArticle.supplierId} Leverantör
                    </Form.Label>
                    <Form.Select
                      aria-label="Leverantör"
                      value={modalArticle.supplierId || undefined}
                      onChange={(e) =>
                        articleSupplierInputHandler(e.target.value)
                      }
                    >
                      <option key={0} value={undefined}>
                        - Ingen leverantör vald -
                      </option>
                      {suppliers.map((supplier) => (
                        <option key={supplier.id} value={supplier.id}>
                          {supplier.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Artikelstatus</Form.Label>
                    <Form.Select
                      aria-label="Artikelstatus"
                      value={modalArticle.articleStatusId || ""}
                      onChange={(e) =>
                        articleArticleStatusInputHandler(e.target.value)
                      }
                    >
                      {articleStatuses.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Externt artikelnummer</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.externalArticleCode ?? ""}
                      onInput={articleExternalArticleCodeInputHandler}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Externt id</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.externalId ?? ""}
                      onInput={articleExternalIdInputHandler}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Beskrivning</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.description ?? ""}
                      onInput={articleDescriptionInputHandler}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Intern kommentar</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.internalComment ?? ""}
                      onInput={articleInternalCommentInputHandler}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>FO-Märkning</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.purchaseOrderComment ?? ""}
                      onInput={articlePurchaseOrderCommentInputHandler}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>IO-Märkning</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.salesOrderComment ?? ""}
                      onInput={articleSalesOrderCommentInputHandler}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Inköpspris</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.purchasePrice ?? ""}
                      onInput={articlePurchasePriceInputHandler}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Försäljningspris</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.salesPrice ?? ""}
                      onInput={articleSalesPriceInputHandler}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Moms %</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.vat ?? "25"}
                      onInput={articleVatInputHandler}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={6} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Totalt exkl. moms</Form.Label>
                    <Form.Control
                      type="text"
                      value={modalArticle.totalExVat ?? ""}
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Totalt inkl. moms</Form.Label>
                    <Form.Control
                      type="text"
                      value={modalArticle.totalIncVat ?? ""}
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Container>
              {!orderData.exported && (
                <div className="float-start">
                  <Button
                    className="mb-3"
                    variant="secondary"
                    onClick={removeArticleHandler}
                  >
                    Ta bort artikel
                  </Button>
                </div>
              )}

              <div className="float-end d-inline-flex">
                <div className="me-3">
                  <Form.Check
                    type="switch"
                    label="Klarmarkerad"
                    className="mt-2"
                    onChange={validatedHandler}
                    checked={modalArticle.verified}
                  />
                </div>

                <div>
                  <Button
                    className="ms-3 mb-3"
                    variant="primary"
                    onClick={saveArticleInModalHandler}
                  >
                    Spara ändringar
                  </Button>
                </div>
              </div>
            </Container>
          </Modal.Footer>
        </Modal>
        {!orderData.exported && (
          <Button
            variant="outline-primary"
            className="mb-3"
            onClick={addNewArticleHandler}
          >
            Lägg till artikel <Plus />
          </Button>
        )}

        <hr />

        <div className="text-end">
          <Button
            variant="primary"
            className="mb-3"
            onClick={handleAddOrder}
            disabled={isWaitingOrderPostResponse}
          >
            Lägg till order
          </Button>
        </div>

        <Alert key="alert" variant="danger" show={displayError}>
          <p>Något gick fel, kontrollera uppgifterna och försök igen.</p>
        </Alert>
      </Form>
    </div>
  );
};

export default OrderAdd;
