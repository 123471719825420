import axios from "axios";
import { AppDispatch } from "../store";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { getApiUrl } from "../helpers/environmentHelper";
import { OrderStatus } from "../../models/responses/orderStatus";
import { setOrderStatus, setOrderStatusList, setOrderStatusListForMenu, setIsWaitingOrderStatusGetResponse } from "../slices/orderStatusSlice";

export const getOrderStatusesForMenu =
  (instance: IPublicClientApplication, account: AccountInfo | null) =>
    async (dispatch: AppDispatch) => {
      const baseUrl = getApiUrl(window.location.hostname, 1);
      dispatch(setIsWaitingOrderStatusGetResponse(true));

      try {
        let menuData = localStorage.getItem("menu");
        if (menuData) {
          let menuObject = JSON.parse(menuData);
          dispatch(setOrderStatusListForMenu({ orderStatusListForMenu: menuObject }));
        }

        if (account) {
          instance
            .acquireTokenSilent({
              scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
              account: account,
              authority:
                "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
            })
            .then(async (response) => {
              if (response) {
                const getData: OrderStatus[] = (
                  await axios.get(`${baseUrl}/orderStatuses/menu`, {
                    timeout: 10000,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${response.accessToken}`,
                    },
                  })
                ).data;
                localStorage.setItem("menu", JSON.stringify(getData));
                dispatch(setIsWaitingOrderStatusGetResponse(false));
                dispatch(setOrderStatusListForMenu({ orderStatusListForMenu: getData }));
              }
            });
        }
      } catch (error) {
        dispatch(setIsWaitingOrderStatusGetResponse(false));
        console.log(error);
      }
    };

export const getOrderStatuses=
  (instance: IPublicClientApplication, account: AccountInfo | null) =>
    async (dispatch: AppDispatch) => {
      const baseUrl = getApiUrl(window.location.hostname, 1);
      dispatch(setIsWaitingOrderStatusGetResponse(true));

      try {
        if (account) {
          instance
            .acquireTokenSilent({
              scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
              account: account,
              authority:
                "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
            })
            .then(async (response) => {
              if (response) {
                const getData: OrderStatus[] = (
                  await axios.get(`${baseUrl}/orderStatuses`, {
                    timeout: 10000,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${response.accessToken}`,
                    },
                  })
                ).data;
                dispatch(setIsWaitingOrderStatusGetResponse(false));
                dispatch(setOrderStatusList({ setOrderStatusList: getData }));
              }
            });
        }
      } catch (error) {
        dispatch(setIsWaitingOrderStatusGetResponse(false));
        console.log(error);
      }
    };

export const getOrderStatusById =
  (
    instance: IPublicClientApplication, 
    account: AccountInfo | null,
    id: string | undefined
  ) =>
    async (dispatch: AppDispatch) => {
      if (!id) return;
      const baseUrl = getApiUrl(window.location.hostname, 1);
      dispatch(setIsWaitingOrderStatusGetResponse(true));

      try {
               if (account) {
          instance
            .acquireTokenSilent({
              scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
              account: account,
              authority:
                "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
            })
            .then(async (response) => {
              if (response) {
                const getData: OrderStatus = (
                  await axios.get(`${baseUrl}/orderStatuses/${id}`, {
                    timeout: 10000,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${response.accessToken}`,
                    },
                  })
                ).data;
                dispatch(setIsWaitingOrderStatusGetResponse(false));
                dispatch(setOrderStatus({ orderStatus: getData }));
              }
            });
        }
      } catch (error) {
        dispatch(setIsWaitingOrderStatusGetResponse(false));
        console.log(error);
      }
    };