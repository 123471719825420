import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultOrderStatus, OrderStatus } from "../../models/responses/orderStatus";

export interface OrderStatusState {
    orderStatusListForMenu: OrderStatus[];
    orderStatus: OrderStatus;
    setOrderStatusList: OrderStatus[];
    isWaitingOrderStatusGetResponse?: boolean;
}

const initialState: OrderStatusState = {
    orderStatusListForMenu: [],
    setOrderStatusList: [],
    orderStatus: { ...defaultOrderStatus },
    isWaitingOrderStatusGetResponse: false,
};

const orderStatusSlice = createSlice({
    name: 'orderStatus',
    initialState,
    reducers: {
        setOrderStatusListForMenu: (state, action: PayloadAction<{
            orderStatusListForMenu: OrderStatus[];
        }>) => {
            state.orderStatusListForMenu = action.payload.orderStatusListForMenu || initialState;
            return state;
        },  
        setOrderStatusList: (state, action: PayloadAction<{
            setOrderStatusList: OrderStatus[];
        }>) => {
            state.setOrderStatusList = action.payload.setOrderStatusList || initialState;
            return state;
        },
        setOrderStatus: (state, action: PayloadAction<{
            orderStatus: OrderStatus;
        }>) => {
            state.orderStatus = action.payload.orderStatus || initialState;
            return state;
        },
        setIsWaitingOrderStatusGetResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingOrderStatusGetResponse = action.payload;
            return state;
        },
    },
});

export const {
    setOrderStatusListForMenu,
    setOrderStatus,
    setOrderStatusList,
    setIsWaitingOrderStatusGetResponse,
} = orderStatusSlice.actions;

export default orderStatusSlice.reducer;