import axios from "axios";
import { AppDispatch } from "../store";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { getApiUrl } from "../helpers/environmentHelper";
import { ArticleStatus } from "../../models/responses/articleStatus";
import {
  setArticleStatus,
  setArticleStatusList,
  setIsWaitingArticleStatusGetResponse,
} from "../slices/articleStatusSlice";

export const getArticleStatuses =
  (instance: IPublicClientApplication, account: AccountInfo | null) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingArticleStatusGetResponse(true));

    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              const getData: ArticleStatus[] = (
                await axios.get(`${baseUrl}/articleStatuses`, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;
              dispatch(setArticleStatusList({ setArticleStatusList: getData }));
              dispatch(setIsWaitingArticleStatusGetResponse(false));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingArticleStatusGetResponse(false));
      console.log(error);
    }
  };

export const getArticleStatusById =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    id: string | undefined
  ) =>
  async (dispatch: AppDispatch) => {
    if (!id) return;
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingArticleStatusGetResponse(true));

    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              const getData: ArticleStatus = (
                await axios.get(`${baseUrl}/articleStatuses/${id}`, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;
              dispatch(setArticleStatus({ articleStatus: getData }));
              dispatch(setIsWaitingArticleStatusGetResponse(false));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingArticleStatusGetResponse(false));
      console.log(error);
    }
  };
