import { Configuration } from "@azure/msal-browser";

export interface MsalConfiguration {
    configuration: Configuration,
    signout: {
        mainWindowRedirectUri: string;
        postLogoutRedirectUri: string;
    }
}

export function getMsalConfiguration(location: String) {
    let configuration: MsalConfiguration = {
        configuration: {
            auth: {
                clientId: "4709c232-370b-41de-99d1-79689b146405",
                authority: "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
                redirectUri: "https://localhost:3000/dashboard",
                postLogoutRedirectUri: "https://localhost:3000/",
            }
        },
        signout: {
            mainWindowRedirectUri: "https://localhost:3000/",
            postLogoutRedirectUri: "https://localhost:3000/",
        }
    };

    if (location === "adonlineappdev.azurewebsites.net") {
        configuration = {
            configuration: {
                auth: {
                    clientId: "ba7edb7c-2613-41d4-a59a-f48e39434b92",
                    authority: "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
                    redirectUri: "https://adonlineappdev.azurewebsites.net/dashboard",
                    postLogoutRedirectUri: "https://adonlineappdev.azurewebsites.net",
                }
            },
            signout: {
                mainWindowRedirectUri: "https://adonlineappdev.azurewebsites.net",
                postLogoutRedirectUri: "https://adonlineappdev.azurewebsites.net",
            }
        };
    }

    if (location === "adone.se") {
        configuration = {
            configuration: {
                auth: {
                    clientId: "13ab4870-744d-4262-8285-217b23854599",
                    authority: "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
                    redirectUri: "https://adone.se/dashboard",
                    postLogoutRedirectUri: "https://adone.se",
                }
            },
            signout: {
                mainWindowRedirectUri: "https://adone.se",
                postLogoutRedirectUri: "https://adone.se",
            }
        };
    }

    return configuration;
}

export function getApiUrl(location: String, version: number) : String {
    let apiUrl = "https://localhost:52619";

    if (location === "adonlineappdev.azurewebsites.net") {
        apiUrl = "https://adonlineappapidev.azurewebsites.net";
    }

    if (location === "adone.se") {
        apiUrl = "https://adonlineappapiprod.azurewebsites.net";
    }
    return `${apiUrl}/api/v${version}`;
}