import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { exportOrder, getOrderById } from "../../app/services/orderService";
import { useAppDispatch } from "../../app/hooks";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Form from "react-bootstrap/Form";
import {
  Accordion,
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Col,
  ListGroup,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import moment from "moment";
import "moment/locale/sv";
import "./view.scss";
import {
  Check,
  CheckAll,
  ExclamationTriangle,
  Eye,
  FileEarmarkArrowDown,
  PersonFillLock,
  X,
} from "react-bootstrap-icons";
import { Article, defaultArticle } from "../../models/responses/article";
import { defaultOrder, Order } from "../../models/responses/order";
interface Props { }

const OrderView: FC<Props> = () => {
  moment.locale("sv");
  moment.updateLocale(moment.locale(), { invalidDate: "-" });
  const { orderId } = useParams<{ orderId: string }>();
  const dispatch = useAppDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const order = useSelector((state: RootState) => state.order.order);
  const loadingOrders: boolean = useSelector(
    (state: RootState) => state.order.isWaitingOrderGetResponse
  );
  const [orderData, setOrderData] = useState<Order>({ ...defaultOrder });
  const [showArticleModal, setShowArticleModal] = useState(false);
  const [modalArticle, setModalArticle] = useState<Article>({
    ...defaultArticle,
  });
  const [allVerified, setAllVerified] = useState(false);
  const [showExportModal, setShowExportModel] = useState(false);
  const [allowedToExport, setAllowedToExport] = useState(false);
  const [supplierExists, setSupplierExists] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    dispatch(getOrderById(instance, account, orderId));
  }, [account, accounts, dispatch, instance, orderId]);

  useEffect(() => {
    dispatch(getOrderById(instance, account, orderId));
  }, [account, accounts, dispatch, instance, orderId]);

  useEffect(() => {
    checkVerified();
  }, [orderData.articles]);

  useEffect(() => {
    if (order) {
      setOrderData({ ...order });
      checkIfSupplierExists();
      checkIfAllowedToExport();
    }
  }, [order, supplierExists]);

  useEffect(() => {
    setModalArticle({
      ...modalArticle,
      totalExVat: parseFloat(
        (modalArticle.salesPrice * modalArticle.quantity).toFixed(2)
      ),
      totalIncVat: parseFloat(
        (
          modalArticle.salesPrice *
          modalArticle.quantity +
          (1 + modalArticle.vat / 100)
        ).toFixed(2)
      ),
    });
  }, [modalArticle.quantity, modalArticle.salesPrice, modalArticle.vat]);

  const checkVerified = () => {
    let allVerified = orderData.articles?.every((art) => {
      if (art.verified) return true;
      return false;
    });

    if (allVerified) {
      setAllVerified(true);
    } else {
      setAllVerified(false);
    }
  };

  const openArticleModalHandler = (article: Article) => {
    setModalArticle({ ...modalArticle, ...article });
    setShowArticleModal(true);
  };

  const handleExportOrder = () => {
    setDisplayError(false);
    dispatch(exportOrder(instance, account, orderId, onSuccess));
  };

  const closeArticleModalHandler = () => {
    setShowArticleModal(false);
  };

  const checkIfAllowedToExport = () => {
    let allowed = true;
    if (!orderData) return;

    if (!orderData.articles || orderData.articles.length <= 0) allowed = false;

    if (!allVerified) allowed = false;

    if (!orderData.registrationNumber || orderData.registrationNumber === "")
      allowed = false;

    if (!orderData.aoNumber || orderData.aoNumber === "") allowed = false;

    if (!supplierExists) allowed = false;

    setAllowedToExport(allowed);
  };

  const checkIfSupplierExists = () => {
    let exists = true;

    if (!orderData.supplier) {
      if (!orderData.articles) exists = false;
      else {
        let allHaveSupplier = orderData.articles.every((art) => {
          if (art.supplierId) return true;
          return false;
        });
        if (!allHaveSupplier) exists = false;
      }
    }
    setSupplierExists(exists);
  };

  const handleCloseExportModal = () => setShowExportModel(false);
  const handleShowExportModal = () => setShowExportModel(true);

  const onSuccess = (orderId: string | null) => {
    if (orderId) {
      setOrderData({ ...orderData, exported: true });
    } else {
      setDisplayError(true);
    }
    setShowExportModel(false);
  };

  return (
    <div>
      {loadingOrders ? (
        <>
          <Row>
            <Col className="text-middle">
              <Spinner variant="primary" />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Breadcrumb>
            <Breadcrumb.Item href={`/orderStatus/${orderData.orderStatus?.id}`}>
              {orderData.orderStatus?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{orderData.customer?.name}</Breadcrumb.Item>
          </Breadcrumb>
          <hr />
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.orderStatus?.name || ""}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                {orderData.exported && (
                  <div className="export-badge">
                    <Badge bg="success"> Ordern är exporterad till AX</Badge>
                  </div>
                )}
              </Col>
              <Col xs={12} md={3}>
                {orderData.filename && (
                  <>
                    <br />
                    <Button
                      variant="outline-primary"
                      className="mt-2 float-end"
                      href={`${orderData.filename}`}
                    >
                      Hämta orderfil <FileEarmarkArrowDown />
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Kund</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.customer?.name || ""}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Ansvarig</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.assignedTo}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Registreringsnummer{" "}
                    {(!orderData.registrationNumber ||
                      orderData.registrationNumber === "") && (
                        <Badge bg="danger">Krävs för export</Badge>
                      )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.registrationNumber}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>VIN</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.vin}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Bilmärke</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.carInfo}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    AO-nummer{" "}
                    {(!orderData.aoNumber || orderData.aoNumber === "") && (
                      <Badge bg="danger">Krävs för export</Badge>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.aoNumber}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>FO-nummer</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.purchaseOrderNumber}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>IO-nummer</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.salesOrderNumber}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Ordertyp</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.orderType?.name || ""}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Beställd av</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.orderedBy}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Uppföljning</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.followUp}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>E-post innehåll</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.emailBody}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Lagerplats</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.warehouseLocation}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Leverantör</Form.Label>
                  <Form.Control
                    type="text"
                    value={orderData.supplier?.name || ""}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Inkommen</Form.Label>
                  <Form.Control
                    type="text"
                    value={moment(orderData.arriveDate).format("L")}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Bokad (prod.dag)</Form.Label>
                  <Form.Control
                    type="text"
                    value={moment(orderData.bookingDate).format("L")}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Leveransdatum</Form.Label>
                  <Form.Control
                    type="text"
                    value={moment(orderData.deliveryDate).format("L")}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Upphämtningsdatum</Form.Label>
                  <Form.Control
                    type="text"
                    value={moment(orderData.pickupDate).format("L")}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Beställt</Form.Label>
                  <Form.Control
                    type="text"
                    value={moment(orderData.orderDate).format("L")}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Kommentar</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={orderData.comment}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Intern kommentar</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={orderData.internalComment}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            {orderData.articles && orderData.articles.length > 0 && (
              <Table responsive striped bordered hover>
                <thead>
                  <tr key="-1">
                    <th>
                      {allVerified ? (
                        <CheckAll className="text-success" />
                      ) : (
                        <ExclamationTriangle className="text-danger" />
                      )}
                    </th>
                    <th>Antal</th>
                    <th>Beskrivning</th>
                    <th>Art.nr</th>
                    <th>Inköpspris</th>
                    <th>Försäljningspris</th>
                    <th>Leverantör</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {orderData?.articles?.map((article) => (
                    <tr
                      role="button"
                      key={article.rowNumber}
                      onClick={() => openArticleModalHandler(article)}
                    >
                      <td>
                        {!article.verified ? (
                          <X className="text-danger" />
                        ) : (
                          <Check className="text-success" />
                        )}
                      </td>
                      <td>{article.quantity}</td>
                      <td>{article.description}</td>
                      <td>{article.articleNumber}</td>
                      <td>{article.purchasePrice}</td>
                      <td>{article.salesPrice}</td>
                      <td>{article.supplier?.name}</td>
                      <td align="center">
                        <Eye />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Form>

          <Modal
            size="xl"
            show={showArticleModal}
            onHide={() => closeArticleModalHandler()}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {`${modalArticle.articleNumber} - ${modalArticle.description}`}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col xs={6} md={1}>
                    <Form.Group className="mb-3">
                      <Form.Label>Antal</Form.Label>
                      <Form.Control
                        type="number"
                        defaultValue={modalArticle.quantity ?? ""}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={5}>
                    <Form.Group className="mb-3">
                      <Form.Label>Artikelnummer</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={modalArticle.articleNumber}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Ersätter</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={modalArticle.replacement}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Leverantör</Form.Label>
                      <Form.Control
                        type="text"
                        value={modalArticle.supplier?.name}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Artikelstatus</Form.Label>
                      <Form.Control
                        value={modalArticle.articleStatus?.name}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Externt artikelnummer</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={modalArticle.externalArticleCode}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Externt id</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={modalArticle.externalId}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Beskrivning</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={modalArticle.description}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Intern kommentar</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={modalArticle.internalComment}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>FO-Märkning</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={modalArticle.purchaseOrderComment}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>IO-Märkning</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={modalArticle.salesOrderComment}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Inköpspris</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={modalArticle.purchasePrice}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Försäljningspris</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={modalArticle.salesPrice}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Moms</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={modalArticle.vat}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs={6} md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Totalt exkl. moms</Form.Label>
                      <Form.Control
                        type="text"
                        value={modalArticle.totalExVat}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col xs={6} md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Totalt inkl. moms</Form.Label>
                      <Form.Control
                        type="text"
                        value={modalArticle.totalIncVat}
                        readOnly
                        disabled
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <div className="float-end d-inline-flex">
                <div className="me-3 mt-2">
                  {modalArticle.verified ? (
                    <Badge bg="success">Klarmarkerad</Badge>
                  ) : (
                    <Badge bg="danger">Inte klarmarkerad</Badge>
                  )}
                </div>
                <Button
                  className="mb-3"
                  variant="outline-primary"
                  onClick={closeArticleModalHandler}
                >
                  Stäng
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          <Row>
            <div>
              {orderData.userId &&
                orderData.userId !== account!.localAccountId ? (
                <span className="float-end">
                  <Button
                    variant="primary"
                    href={`/orderStatus/${orderData.orderStatus?.id}/orders/edit/${orderData.id}`}
                  >
                    Lås upp <PersonFillLock />
                  </Button>
                  <span className="username-text">
                    Redigeras av: {orderData.userName}
                  </span>
                </span>
              ) : (
                <Button
                  variant="primary"
                  className="float-end"
                  href={`/orderStatus/${orderData.orderStatus?.id}/orders/edit/${orderData.id}`}
                >
                  Redigera
                </Button>
              )}
              {!orderData.exported && (
                <>
                  <Button
                    variant="primary"
                    className="float-end me-2"
                    onClick={handleShowExportModal}
                    disabled={!allowedToExport}
                  >
                    Exportera till AX
                  </Button>
                  {!supplierExists && (
                    <Badge bg="danger float-end mt-2 me-3">
                      Leverantör krävs på order eller alla artiklar i ordern för
                      export
                    </Badge>
                  )}
                </>
              )}
              <Modal show={showExportModal} onHide={handleCloseExportModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Exportera order till AX</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Är du säker på att du vill exportera ordern till AX?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseExportModal}>
                    Avbryt
                  </Button>
                  <Button variant="primary" onClick={handleExportOrder}>
                    Exportera till AX
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <Alert
              key="alert"
              variant="danger"
              show={displayError}
              className="mt-3"
            >
              <p>Något gick fel vid exporten till AX, vänligen försök igen.</p>
            </Alert>
          </Row>
          <div className="mt-3">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Historik</Accordion.Header>
                <Accordion.Body>
                  <ListGroup as="ul">
                    {orderData?.orderHistory?.map((history) => (
                      <ListGroup.Item as="li" key={history.id}>
                        {(() => {
                          switch (history.action.toLowerCase()) {
                            case "add":
                              return <span>Skapades av </span>;
                            case "update":
                              return <span>Uppdaterades av </span>;
                            case "assign":
                              return <span>Låstes av </span>;
                            case "unassign":
                              return <span>Låstes upp av </span>;
                            case "export":
                              return <span>Exportades av </span>;
                            default:
                              return <span>-</span>;
                          }
                        })()}
                        <span>{history.userName} </span>
                        <span>
                          {" "}
                          den{" "}
                          {moment.utc(history.createdAt).local().format("LLL")}
                        </span>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderView;
