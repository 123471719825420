import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Props {}

const Home: FC<Props> = () => {
  const navigate = useNavigate();

  useEffect(() => {
     navigate("/dashboard");
  }, []);

  return (
    <div>
    </div>
  );
};

export default Home;
