export interface OrderType{
    id: string;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    name: string;
}

export const defaultOrderType: OrderType = {
    id: "",
    createdAt: "",
    updatedAt: null,
    deletedAt: null,
    name: "",
};