import { OrderHistory } from "./orderHistory";
import { Article } from "./article";
import { Customer } from "./customer";
import { OrderStatus } from "./orderStatus";
import { OrderType } from "./orderType";
import { Supplier } from "./supplier";

export interface Order {
  id: string;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  registrationNumber: string;
  vin: string;
  aoNumber: string;
  carInfo: string;
  purchaseOrderNumber: string;
  salesOrderNumber: string;
  comment: string;
  internalComment: string;
  assignedTo: string;
  orderedBy: string;
  followUp: string;
  emailBody: string;
  warehouseLocation: string;
  userName: string;
  userEmail: string;
  userId: string;
  filename: string;
  exported: boolean;
  arriveDate: string | null;
  bookingDate: string | null;
  deliveryDate: string | null;
  pickupDate: string | null;
  printDate: string | null;
  orderDate: string | null;
  articles: Article[] | null;
  supplierId: string | null;
  supplier: Supplier | null;
  customer: Customer | null;
  customerId: string | null;
  orderType: OrderType | null;
  orderTypeId: string | null;
  orderStatus: OrderStatus | null;
  orderStatusId: string | null;
  orderHistory: OrderHistory[] | null;
}

export const defaultOrder: Order = {
  id: "",
  createdAt: "",
  updatedAt: null,
  deletedAt: null,
  registrationNumber: "",
  vin: "",
  aoNumber: "",
  carInfo: "",
  purchaseOrderNumber: "",
  salesOrderNumber: "",
  comment: "",
  internalComment: "",
  filename: "",
  assignedTo: "",
  orderedBy: "",
  followUp: "",
  emailBody: "",
  warehouseLocation: "",
  userName: "",
  userEmail: "",
  userId: "",
  exported: false,
  arriveDate: null,
  bookingDate: null,
  deliveryDate: null,
  pickupDate: null,
  printDate: null,
  orderDate: null,
  articles: null,
  supplierId: null,
  supplier: null,
  customer: null,
  customerId: null,
  orderTypeId: null,
  orderType: null,
  orderStatus: null,
  orderStatusId: null,
  orderHistory: null,
}