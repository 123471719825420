export interface ArticleStatus{
    id: string;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    name: string;
}

export const defaultArticleStatus: ArticleStatus = {
    id: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    name: "",
}