export interface Customer {
    id: string;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    name: string;
    externalId: string;
    address: string;
    email: string;
    phone: string;
    documentFolder: string;
    comment: string;
}

export const defaultCustomer: Customer = {
    id: '',
    createdAt: '',
    updatedAt: null,
    deletedAt: null,
    name: '',
    externalId: '',
    address: '',
    email: '',
    phone: '',
    documentFolder: '',
    comment: '',
};