export interface OrderUser {
    id: string;
    userName: string;
    userEmail: string;
    userId: string;
}

export const defaultOrderUser: OrderUser = {
    id: "",
    userName: "",
    userEmail: "",
    userId: ""
};