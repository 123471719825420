import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultOrderType, OrderType } from "../../models/responses/orderType";

export interface OrderTypeState {
    orderTypeList: OrderType[];
    orderType: OrderType;
    isWaitingOrderTypeGetResponse?: boolean;
}

const initialState: OrderTypeState = {
    orderTypeList: [],
    orderType: { ...defaultOrderType },
};

const orderTypeSlice = createSlice({
    name: 'orderType',
    initialState,
    reducers: {
        setOrderTypeListForMenu: (state, action: PayloadAction<{
            orderTypeList: OrderType[];
        }>) => {
            state.orderTypeList = action.payload.orderTypeList || initialState;
            return state;
        },
        setOrderType: (state, action: PayloadAction<{
            orderType: OrderType;
        }>) => {
            state.orderType = action.payload.orderType || initialState;
            return state;
        },
        setIsWaitingOrderTypeGetResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingOrderTypeGetResponse = action.payload;
            return state;
        },
    },
});

export const {
    setOrderTypeListForMenu,
    setOrderType,
    setIsWaitingOrderTypeGetResponse,
} = orderTypeSlice.actions;

export default orderTypeSlice.reducer;