import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCustomerById } from "../../app/services/customerService";
import { useAppDispatch } from "../../app/hooks";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Form from "react-bootstrap/Form";
import {
  Accordion,
  Breadcrumb,
  Button,
  Col,
  ListGroup,
  Row,
} from "react-bootstrap";
import moment from "moment";
import "moment/locale/sv";

interface Props {}

const CustomerView: FC<Props> = () => {
  moment.locale("sv");
  const { customerId } = useParams<{ customerId: string }>();
  const dispatch = useAppDispatch();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const customer = useSelector((state: RootState) => state.customer);

  useEffect(() => {
    dispatch(getCustomerById(instance, account, customerId));
  }, [account, accounts, dispatch, instance, customerId]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="/customers">Kunder</Breadcrumb.Item>
        <Breadcrumb.Item active>{customer.customer.name}</Breadcrumb.Item>
      </Breadcrumb>
      <hr />
      <Form>
        <Row>
          <Col xs={12} md={6}>
          <Form.Group className="mb-3">
              <Form.Label>Kund</Form.Label>
              <Form.Control
                type="text"
                value={customer.customer.name}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
          <Form.Group className="mb-3">
              <Form.Label>Kundnummer (AX)</Form.Label>
              <Form.Control
                type="text"
                value={customer.customer.externalId ?? ""}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Adress</Form.Label>
              <Form.Control
                type="text"
                value={customer.customer.address ?? ""}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>E-post</Form.Label>
              <Form.Control
                type="text"
                value={customer.customer.email ?? ""}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Telefon</Form.Label>
              <Form.Control
                type="text"
                value={customer.customer.phone}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Dokumentmapp</Form.Label>
              <Form.Control
                type="email"
                value={customer.customer.documentFolder}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Anteckning</Form.Label>
              <Form.Control
                as="textarea"
                value={customer.customer.comment ?? ""}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Row>
        <div className="text-end">
          <Button variant="primary" href={`/customers/${customerId}/edit`}>
            Redigera
          </Button>
        </div>
      </Row>

      <div className="mt-3">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Historik</Accordion.Header>
            <Accordion.Body>
              <ListGroup as="ul">
                <ListGroup.Item as="li">
                  <span>
                    Updaterades senast{" "}
                    {moment
                      .utc(customer.customer.updatedAt)
                      .local()
                      .format("LLL")}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <span>
                    Skapades den{" "}
                    {moment
                      .utc(customer.customer.createdAt)
                      .local()
                      .format("LLL")}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default CustomerView;
