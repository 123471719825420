import { useEffect, type FC } from "react";
import "./index.scss";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useAppDispatch } from "../../app/hooks";
import { getUser } from "../../app/services/userService";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getMsalConfiguration } from "../../app/helpers/environmentHelper";
import { useLocation } from "react-router-dom";
import { getOrderStatusesForMenu } from "../../app/services/orderStatusService";
import { Dropdown } from "react-bootstrap";

interface Props {}

const Navigation: FC<Props> = () => {
  const orderStatuses = useSelector((state: RootState) => state.orderStatus);
  const dispatch = useAppDispatch();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const user = useSelector((state: RootState) => state.user);
  const displayName: string | null = user.displayName ?? "ERROR";

  useEffect(() => {
    dispatch(getUser(instance, account));
  }, [account, accounts, dispatch, instance]);

  useEffect(() => {
    dispatch(getOrderStatusesForMenu(instance, account));
  }, [account, accounts, dispatch, instance]);

  const configuration = getMsalConfiguration(window.location.hostname).signout;

  function signOutClickHandler() {
    const logoutRequest = {
      account: account,
      mainWindowRedirectUri: configuration.mainWindowRedirectUri,
      postLogoutRedirectUri: configuration.postLogoutRedirectUri,
    };
    instance.logoutPopup(logoutRequest);
  }

  function signOffClickHandler() {
    instance.logout();
  }

  const location = useLocation().pathname;

  return (
    <Navbar
      expand="lg"
      bg="primary"
      data-bs-theme="dark"
      className="navigation-style"
    >
      <Container>
        <Navbar.Brand className="brand" href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="35"
            fill="none"
            viewBox="0 0 57 32"
          >
            <path
              fill="#fff"
              d="M39.049 5.728L36.865 0h-16.3l2.132 5.728h16.352zM12.053 8.245c-2.02.01-4.005.52-5.772 1.482a11.95 11.95 0 00-4.338 4.024 11.649 11.649 0 00-.727 11.336 11.874 11.874 0 003.79 4.527 12.156 12.156 0 005.537 2.183c2.003.26 4.04.02 5.924-.698a12.033 12.033 0 004.854-3.407l.963 2.416h6.362l-5.158-13.956a12.024 12.024 0 00-4.451-5.721 12.357 12.357 0 00-6.984-2.186zm0 17.892a6.2 6.2 0 01-3.405-1.005 6.04 6.04 0 01-2.262-2.696 5.918 5.918 0 01-.356-3.477 5.983 5.983 0 011.672-3.084 6.155 6.155 0 013.134-1.65 6.222 6.222 0 013.539.339 6.1 6.1 0 012.75 2.215 5.94 5.94 0 011.032 3.343c0 1.593-.642 3.12-1.786 4.248a6.176 6.176 0 01-4.318 1.767zM45.858 0h-6.38l3.113 8.448a12.244 12.244 0 00-5.275.14 12.117 12.117 0 00-4.707 2.342 11.858 11.858 0 00-3.235 4.097 11.655 11.655 0 00-1.14 5.062 11.684 11.684 0 002.167 6.869 12.013 12.013 0 005.817 4.365c2.353.826 4.91.912 7.315.247a12.054 12.054 0 006.108-3.963l.945 2.518h6.362L45.858 0zm-5.485 26.137a6.2 6.2 0 01-3.401-1.013 6.04 6.04 0 01-2.255-2.7 5.918 5.918 0 01-.348-3.475 5.984 5.984 0 011.675-3.08 6.156 6.156 0 013.135-1.646 6.221 6.221 0 013.536.343 6.098 6.098 0 012.747 2.215 5.94 5.94 0 011.032 3.341 5.962 5.962 0 01-1.793 4.254 6.176 6.176 0 01-4.328 1.761z"
            ></path>
          </svg>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {orderStatuses.orderStatusListForMenu.map((status) => (
            <Nav key={status.id}>
              <Nav.Link
                className={
                  location.toLocaleLowerCase().includes(status.id)
                    ? "active-nav-item"
                    : ""
                }
                href={`/orderstatus/${status.id}`}
              >
                {status.name}
              </Nav.Link>
            </Nav>
          ))}
          <Nav>
            <Nav.Link
              className={
                location.toLocaleLowerCase().includes("suppliers")
                  ? "active-nav-item"
                  : ""
              }
              href="/suppliers"
            >
              Leverantörer
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              className={
                location.toLocaleLowerCase().includes("customers")
                  ? "active-nav-item"
                  : ""
              }
              href="/customers"
            >
              Kunder
            </Nav.Link>
          </Nav>
          <Nav className="navbar-user">
            <Nav.Link>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-signout">
                  {displayName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <Dropdown.Item href="/" onClick={signOutClickHandler}>
                    Växla användare
                  </Dropdown.Item> */}
                  <Dropdown.Item href="/" onClick={signOffClickHandler}>
                    Logga ut
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
