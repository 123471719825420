import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultSupplier, Supplier } from "../../models/responses/supplier";
import { QuerySupplier } from "../../models/responses/querySupplier";

export interface SupplierState {
    supplierList: Supplier[];
    supplier: Supplier;
    shouldUpdateSupplierList: boolean;
    runningSupplierImport: boolean;
    isWaitingSupplierPostResponse: boolean;
    isWaitingSupplierPutResponse: boolean;
    isWaitingSupplierGetResponse: boolean;
    querySupplierList: Supplier[] | null;
    totalQuerySupplier: number;    
}

const initialState: SupplierState = {
    supplierList: [],
    supplier: { ...defaultSupplier },
    shouldUpdateSupplierList: false,
    runningSupplierImport: false,
    isWaitingSupplierPostResponse:false,
    isWaitingSupplierPutResponse:false,
    isWaitingSupplierGetResponse: false,
    querySupplierList: [],
    totalQuerySupplier: 0
};

const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
        setSupplierList: (state, action: PayloadAction<{
            suppliers: Supplier[];
        }>) => {
            state.supplierList = action.payload.suppliers || [];
            return state;
        },
        setSupplier: (state, action: PayloadAction<{
            supplier: Supplier;
        }>) => {
            state.supplier = action.payload.supplier || initialState;
            return state;
        },
        setIsWaitingSupplierPostResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingSupplierPostResponse = action.payload;
        },
        setIsWaitingSupplierPutResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingSupplierPutResponse = action.payload;
        },
        setIsWaitingSupplierGetResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingSupplierGetResponse = action.payload;
        },
        reloadSupplierList: (state, action: PayloadAction<boolean>) => {
            state.shouldUpdateSupplierList = action.payload;
            return state;
        },
        runningSupplierImport: (state, action: PayloadAction<boolean>) => {
            state.runningSupplierImport = action.payload;
            return state;
        },
        setQuerySupplierList: (state, action: PayloadAction<{
            query: QuerySupplier;
        }>) => {
            state.querySupplierList = action.payload.query.suppliers || initialState.querySupplierList;
            state.totalQuerySupplier = action.payload.query.totalCount || initialState.totalQuerySupplier;
            return state;
        }
    },
});

export const {
    setSupplierList,
    setSupplier,
    setIsWaitingSupplierPostResponse,
    setIsWaitingSupplierPutResponse,
    setIsWaitingSupplierGetResponse,
    reloadSupplierList,
    runningSupplierImport,   
    setQuerySupplierList,
} = supplierSlice.actions;

export default supplierSlice.reducer;