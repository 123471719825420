import { FC, useEffect, useState } from "react";
import { queryCustomers } from "../../app/services/customerService";
import { useAppDispatch } from "../../app/hooks";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Customer } from "../../models/responses/customer";
import { Col, Pagination, Row, Spinner } from "react-bootstrap";
import { Eye, Plus } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

interface Props { }

const Customers: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const customers: Customer[] = useSelector(
    (state: RootState) => state.customer.queryCustomerList || []
  );
  const loadingCustomers = useSelector(
    (state: RootState) => state.customer.isWaitingCustomerGetResponse
  );
  const [page, setPage] = useState(1);
  const [pageSize] = useState(50);
  const total = useSelector(
    (state: RootState) => state.customer.totalQueryCustomer
  );
  const totalPages = Math.ceil((total || 0) / pageSize);

  useEffect(() => {
    dispatch(queryCustomers(instance, account, page, pageSize));
  }, [account, accounts, dispatch, instance, page, pageSize]);

  const openCustomerHandler = (customer: Customer) => {
    navigate(`/customers/${customer.id}`, { relative: "path" });
  };

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <h1>Kunder</h1>
        </Col>
        <Col className="text-end">
          <Button
            className="mt-2 ms-2"
            variant="outline-primary"
            href={`/customers/add`}
          >
            Lägg till <Plus />
          </Button>
        </Col>
      </Row>

      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Kund.nr (AX)</th>
            <th>Namn</th>
            <th>Tel.</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => (
            <tr
              key={customer.id}
              role="button"
              onClick={() => openCustomerHandler(customer)}
            >
              <td>{customer.externalId}</td>
              <td>{customer.name}</td>
              <td>{customer.phone}</td>
              <td align="center">
                <Eye />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {loadingCustomers && (
        <>
          <Row className="mb-3">
            <Col className="text-center">
              <Spinner variant="primary" />
            </Col>
          </Row>
        </>
      )}

      <Pagination className="justify-content-center">
        <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
        <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page === 1} />
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === page}
            onClick={() => setPage(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => setPage(page + 1)} disabled={page === totalPages} />
        <Pagination.Last onClick={() => setPage(totalPages)} disabled={page === totalPages} />
      </Pagination>
    </div>
  );
};

export default Customers;
