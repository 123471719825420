import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  assignOrder,
  getOrderById,
  getOrderUserById,
  putOrder,
  removeAssignOrder,
} from "../../app/services/orderService";
import { useAppDispatch } from "../../app/hooks";
import { useAccount, useMsal } from "@azure/msal-react";

import Form from "react-bootstrap/Form";
import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { defaultOrder, Order } from "../../models/responses/order";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getOrderTypes } from "../../app/services/orderTypeService";
import { getSuppliers } from "../../app/services/supplierService";
import { getCustomers } from "../../app/services/customerService";
import {
  getOrderStatusById,
  getOrderStatuses,
} from "../../app/services/orderStatusService";
import { OrderStatus } from "../../models/responses/orderStatus";
import { Customer } from "../../models/responses/customer";
import { OrderType } from "../../models/responses/orderType";
import { Supplier } from "../../models/responses/supplier";
import moment from "moment";
import {
  Check,
  CheckAll,
  ExclamationTriangle,
  Lock,
  Pen,
  Plus,
  X,
} from "react-bootstrap-icons";
import { Article, defaultArticle } from "../../models/responses/article";
import { ArticleStatus } from "../../models/responses/articleStatus";
import { getArticleStatuses } from "../../app/services/articleStatusService";
import { setIsWaitingOrderPutResponse } from "../../app/slices/orderSlice";

interface Props {}

const OrderEdit: FC<Props> = () => {
  moment.locale("sv");
  moment.updateLocale(moment.locale(), { invalidDate: "-" });
  const { orderId } = useParams<{ orderId: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [displayError, setDisplayError] = useState(false);
  const { orderStatusId } = useParams<{ orderStatusId: string }>();
  const order = useSelector((state: RootState) => state.order.order);
  const orderUser = useSelector((state: RootState) => state.order.orderUser);
  const isLoadingOrderStatuses = useSelector(
    (state: RootState) => state.orderStatus.isWaitingOrderStatusGetResponse
  );
  const isLoadingArticleStatuses = useSelector(
    (state: RootState) => state.articleStatus.isWaitingArticleStatusGetResponse
  );
  const isLoadingOrderTypes = useSelector(
    (state: RootState) => state.orderTypes.isWaitingOrderTypeGetResponse
  );
  const isLoadingSuppliers = useSelector(
    (state: RootState) => state.supplier.isWaitingSupplierGetResponse
  );
  const isLoadingCustomers = useSelector(
    (state: RootState) => state.customer.isWaitingCustomerGetResponse
  );
  const [showAssignedModal, setShow] = useState(false);
  const [showArticleModal, setShowArticleModal] = useState(false);
  const [orderData, setOrderData] = useState<Order>({ ...defaultOrder });
  const [modalArticle, setModalArticle] = useState<Article>({
    ...defaultArticle,
  });
  const [allVerified, setAllVerified] = useState(false);

  useEffect(() => {
    dispatch(getOrderById(instance, account, orderId));
  }, [account, accounts, dispatch, instance, orderId]);

  useEffect(() => {
    dispatch(assignOrder(instance, account, orderId));
  }, [dispatch, instance, account, orderId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getOrderUserById(instance, account, orderId));
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    orderData.userId = orderUser.userId;
  }, [orderUser]);

  useEffect(() => {
    if (orderData.userId && account?.localAccountId) {
      setShow(orderData.userId !== account.localAccountId);
    }
  }, [orderData.userId, account?.localAccountId, orderUser.id]);

  useEffect(() => {
    if (order) {
      setOrderData({ ...order });
    }
  }, [order]);

  const orderStatus: OrderStatus = useSelector(
    (state: RootState) => state.orderStatus.orderStatus
  );
  const orderStatusList: OrderStatus[] = useSelector(
    (state: RootState) => state.orderStatus.setOrderStatusList
  );
  const customers: Customer[] = useSelector(
    (state: RootState) => state.customer.customerList
  );
  const orderTypes: OrderType[] = useSelector(
    (state: RootState) => state.orderTypes.orderTypeList
  );
  const suppliers: Supplier[] = useSelector(
    (state: RootState) => state.supplier.supplierList
  );
  const articleStatuses: ArticleStatus[] = useSelector(
    (state: RootState) => state.articleStatus.articleStatusList
  );
  const isWaitingOrderPostResponse = useSelector(
    (state: RootState) => state.order.isWaitingOrderPostResponse
  );
  useEffect(() => {
    dispatch(getOrderStatusById(instance, account, orderStatusId));
  }, [account, accounts, dispatch, instance, orderStatusId]);

  useEffect(() => {
    dispatch(getOrderStatuses(instance, account));
  }, [account, accounts, dispatch, instance]);

  useEffect(() => {
    dispatch(getArticleStatuses(instance, account));
  }, [account, accounts, dispatch, instance]);

  useEffect(() => {
    dispatch(getCustomers(instance, account));
  }, [account, accounts, dispatch, instance]);

  useEffect(() => {
    dispatch(getOrderTypes(instance, account));
  }, [account, accounts, dispatch, instance]);

  useEffect(() => {
    dispatch(getSuppliers(instance, account));
  }, [account, accounts, dispatch, instance]);

  useEffect(() => {
    checkVerified();
  }, [orderData.articles]);

  useEffect(() => {
    setModalArticle({
      ...modalArticle,
      totalExVat: parseFloat(
        (modalArticle.salesPrice * modalArticle.quantity).toFixed(2)
      ),
      totalIncVat: parseFloat(
        (
          modalArticle.salesPrice *
          modalArticle.quantity *
          (1 + modalArticle.vat / 100)
        ).toFixed(2)
      ),
    });
  }, [modalArticle.quantity, modalArticle.salesPrice, modalArticle.vat]);

  const customerInputHandler = (e: string) => {
    setOrderData({ ...orderData, customerId: e });
  };
  const assignedToInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, assignedTo: e.target.value });
  };
  const registrationNumberInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderData({ ...orderData, registrationNumber: e.target.value });
  };
  const vinInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, vin: e.target.value });
  };
  const carInfoInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, carInfo: e.target.value });
  };
  const aoNumberInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, aoNumber: e.target.value });
  };
  const purchaseOrderNumberInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderData({ ...orderData, purchaseOrderNumber: e.target.value });
  };
  const salesOrderNumberInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderData({ ...orderData, salesOrderNumber: e.target.value });
  };
  const orderTypeInputHandler = (e: string) => {
    if (e === "") setOrderData({ ...orderData, orderTypeId: null });
    else setOrderData({ ...orderData, orderTypeId: e });
  };
  const orderedByInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, orderedBy: e.target.value });
  };
  const followUpInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, followUp: e.target.value });
  };
  const emailBodyInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, emailBody: e.target.value });
  };
  const warehouseLocationInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderData({ ...orderData, warehouseLocation: e.target.value });
  };
  const supplierInputHandler = (e: string) => {
    if (e === "") setOrderData({ ...orderData, supplierId: null });
    else setOrderData({ ...orderData, supplierId: e });
  };
  const arriveDateInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, arriveDate: e.target.value });
  };
  const bookingDateInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, bookingDate: e.target.value });
  };
  const deliveryDateInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, deliveryDate: e.target.value });
  };
  const pickupDateInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, pickupDate: e.target.value });
  };
  const orderDateInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, orderDate: e.target.value });
  };
  const commentInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({ ...orderData, comment: e.target.value });
  };
  const internalCommentInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrderData({ ...orderData, internalComment: e.target.value });
  };
  const orderStatusInputHandler = (e: string) => {
    setOrderData({ ...orderData, orderStatusId: e });
  };
  const articleListArticleNumberHandler = (id: string, newArticleNumber: string) => {
    setOrderData({
      ...orderData, articles: orderData.articles!.map((article) =>
        article.id === id ? { ...article, articleNumber: newArticleNumber } : article
      ),
    })
  };
  const articleListSalesPriceHandler = (id: string, newSalesPrice: number) => {
    setOrderData({
      ...orderData, articles: orderData.articles!.map((article) =>
        article.id === id ? { ...article, salesPrice: newSalesPrice } : article
      ),
    })
  };
  const articleListPurchasePriceHandler = (id: string, newPurchasePrice: number) => {
    setOrderData({
      ...orderData, articles: orderData.articles!.map((article) =>
        article.id === id ? { ...article, purchasePrice: newPurchasePrice } : article
      ),
    })
  };
  const articleListSupplierHandler = (id: string, newSupplierId: string) => {
    setOrderData({
      ...orderData, articles: orderData.articles!.map((article) =>
        article.id === id ? { ...article, supplierId: newSupplierId } : article
      ),
    })
  };

  // ARTICLES // -----------------------------
  const openArticleModalHandler = (article: Article) => {
    setModalArticle({ ...modalArticle, ...article });
    setShowArticleModal(true);
  };

  const closeArticleModalHandler = () => {
    setShowArticleModal(false);
  };

  const articleArticleNumberInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, articleNumber: e.target.value });
  };
  const articleReplacementInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, replacement: e.target.value });
  };
  const articleQuantityInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, quantity: parseInt(e.target.value) });
  };
  const articleExternalIdInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, externalId: e.target.value });
  };
  const articleExternalArticleCodeInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, externalArticleCode: e.target.value });
  };
  const articleDescriptionInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, description: e.target.value });
  };
  const articlePurchaseOrderCommentInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, purchaseOrderComment: e.target.value });
  };
  const articleSalesOrderCommentInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, salesOrderComment: e.target.value });
  };
  const articleInternalCommentInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({ ...modalArticle, internalComment: e.target.value });
  };
  const articlePurchasePriceInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({
      ...modalArticle,
      purchasePrice: parseInt(e.target.value),
    });
  };
  const articleSalesPriceInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setModalArticle({
      ...modalArticle,
      salesPrice: parseInt(e.target.value),
    });
  };
  const articleVatInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModalArticle({ ...modalArticle, vat: parseInt(e.target.value) });
  };
  const articleSupplierInputHandler = (e: string) => {
    setModalArticle({ ...modalArticle, supplierId: e });
  };
  const articleArticleStatusInputHandler = (e: string) => {
    setModalArticle({ ...modalArticle, articleStatusId: e });
  };

  const saveArticleInModalHandler = () => {
    const savedArticles = orderData.articles?.map<Article>((a) => {
      if (a.rowNumber === modalArticle.rowNumber) return modalArticle;
      return a;
    });

    if (savedArticles) {
      setOrderData({ ...orderData, articles: savedArticles });
    }

    setShowArticleModal(false);
  };

  const validatedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModalArticle({ ...modalArticle, verified: e.target.checked });
  };

  const removeArticleHandler = () => {
    const keptArticles = orderData.articles?.filter((a) => {
      return a.rowNumber !== modalArticle.rowNumber;
    });

    if (keptArticles) {
      setOrderData({ ...orderData, articles: keptArticles });
    }

    setShowArticleModal(false);
  };

  const addNewArticleHandler = () => {
    let newArticle: Article = { ...defaultArticle };
    newArticle.rowNumber = calculateRowNumber();
    newArticle.articleStatusId = articleStatuses[0].id;

    if (orderData.articles === null) return;

    let newList = [...orderData.articles, newArticle];

    setOrderData({
      ...orderData,
      articles: newList,
    });

    setModalArticle(newArticle);
    setShowArticleModal(true);
  };

  const calculateRowNumber = () => {
    if (!orderData.articles) return 0;

    return (Math.max(...orderData.articles!.map((a) => a.rowNumber)) ?? 0) + 1;
  };

  // ARTICLES END // -----------------------------

  const checkVerified = () => {
    let allVerified = orderData.articles?.every((art) => {
      if (art.verified) return true;
      return false;
    });

    if (allVerified) {
      setAllVerified(true);
    } else {
      setAllVerified(false);
    }
  };

  const handleUpdateOrder = () => {
    setDisplayError(false);
    dispatch(putOrder(instance, account, orderData, onSuccess));
  };

  const cancelUpdateOrder = () => {
    dispatch(removeAssignOrder(instance, account, orderId));
    navigateToViewPage();
  };

  const handleAssignOrder = () => {
    dispatch(assignOrder(instance, account, orderId));
    setShow(false);
  };

  const onSuccess = (orderId: string | null) => {
    if (orderId === null) {
      setDisplayError(true);
      return;
    }
    navigateToViewPage();
  };

  const navigateToViewPage = () => {
    setTimeout(() => {
      navigate(
        `/orderStatus/${orderData.orderStatusId}/orders/view/${orderData.id}`,
        { relative: "path" }
      );
    }, 500);
  };

  return (
    <div>
      <Modal show={showAssignedModal} onHide={navigateToViewPage}>
        <Modal.Header closeButton>
          <Modal.Title>Låst order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Den här ordern redigeras av {orderData.userName}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={navigateToViewPage}>
            Stäng
          </Button>
          <Button variant="primary" onClick={handleAssignOrder}>
            Lås upp
          </Button>
        </Modal.Footer>
      </Modal>
      <Breadcrumb>
        <Breadcrumb.Item href={`/orderStatus/${orderStatus.id}`}>
          {orderStatus.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Uppdatera order</Breadcrumb.Item>
      </Breadcrumb>
      <hr />
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>
                Status{" "}
                {isLoadingOrderStatuses && (
                  <div className="ms-3 d-inline-block">
                    <Spinner variant="primary" size="sm" />
                  </div>
                )}
              </Form.Label>
              <Form.Select
                aria-label="Status"
                value={orderData.orderStatusId || ""}
                onChange={(e) => orderStatusInputHandler(e.target.value)}
              >
                {orderStatusList.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={3}>
            {orderData.exported && (
              <div className="export-badge">
                <Badge bg="success"> Ordern är exporterad till AX</Badge>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Kund{" "}
                {isLoadingCustomers && (
                  <div className="ms-3 d-inline-block">
                    <Spinner variant="primary" size="sm" />
                  </div>
                )}
              </Form.Label>
              <Form.Select
                aria-label="Kund"
                value={orderData.customerId || ""}
                onChange={(e) => customerInputHandler(e.target.value)}
                disabled={orderData.exported}
              >
                {customers.map((customer) => (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Ansvarig</Form.Label>
              <Form.Control
                type="text"
                defaultValue={orderData.assignedTo ?? ""}
                onInput={assignedToInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Registreringsnummer</Form.Label>
              <Form.Control
                type="text"
                defaultValue={orderData.registrationNumber ?? ""}
                onInput={registrationNumberInputHandler}
                disabled={orderData.exported}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>VIN</Form.Label>
              <Form.Control
                type="text"
                defaultValue={orderData.vin ?? ""}
                onInput={vinInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Bilmärke</Form.Label>
              <Form.Control
                type="text"
                defaultValue={orderData.carInfo ?? ""}
                onInput={carInfoInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>AO-nummer</Form.Label>
              <Form.Control
                type="text"
                defaultValue={orderData.aoNumber ?? ""}
                onInput={aoNumberInputHandler}
                disabled={orderData.exported}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>FO-nummer</Form.Label>
              <Form.Control
                type="text"
                defaultValue={orderData.purchaseOrderNumber ?? ""}
                onInput={purchaseOrderNumberInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>IO-nummer</Form.Label>
              <Form.Control
                type="text"
                defaultValue={orderData.salesOrderNumber ?? ""}
                onInput={salesOrderNumberInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Ordertyp{" "}
                {isLoadingOrderTypes && (
                  <div className="ms-3 d-inline-block">
                    <Spinner variant="primary" size="sm" />
                  </div>
                )}
              </Form.Label>
              <Form.Select
                aria-label="Ordertyp"
                value={orderData.orderTypeId || ""}
                onChange={(e) => orderTypeInputHandler(e.target.value)}
              >
                <option key={0} value="">
                  - Ingen ordertyp vald -
                </option>
                {orderTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Beställd av</Form.Label>
              <Form.Control
                type="text"
                defaultValue={orderData.orderedBy ?? ""}
                onInput={orderedByInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Uppföljning</Form.Label>
              <Form.Control
                type="text"
                defaultValue={orderData.followUp ?? ""}
                onInput={followUpInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>E-post innehåll</Form.Label>
              <Form.Control
                type="text"
                defaultValue={orderData.emailBody ?? ""}
                onInput={emailBodyInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Lagerplats</Form.Label>
              <Form.Control
                type="text"
                defaultValue={orderData.warehouseLocation ?? ""}
                onInput={warehouseLocationInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>
                Leverantör{" "}
                {isLoadingSuppliers && (
                  <div className="ms-3 d-inline-block">
                    <Spinner variant="primary" size="sm" />
                  </div>
                )}
              </Form.Label>
              <Form.Select
                aria-label="Leverantör"
                value={orderData.supplierId ?? ""}
                onChange={(e) => supplierInputHandler(e.target.value)}
                disabled={orderData.exported}
              >
                <option key={0} value="">
                  - Ingen leverantör vald -
                </option>
                {suppliers.map((supplier) => (
                  <option key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Inkommen</Form.Label>
              <Form.Control
                type="date"
                value={moment(orderData.arriveDate).format("L") ?? ""}
                onInput={arriveDateInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Bokad (prod.dag)</Form.Label>
              <Form.Control
                type="date"
                value={moment(orderData.bookingDate).format("L") ?? ""}
                onInput={bookingDateInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Leveransdatum</Form.Label>
              <Form.Control
                type="date"
                value={moment(orderData.deliveryDate).format("L") ?? ""}
                onInput={deliveryDateInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Upphämtningsdatum</Form.Label>
              <Form.Control
                type="date"
                value={moment(orderData.pickupDate).format("L") ?? ""}
                onInput={pickupDateInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Beställt</Form.Label>
              <Form.Control
                type="date"
                value={moment(orderData.orderDate).format("L") ?? ""}
                onInput={orderDateInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Kommentar</Form.Label>
              <Form.Control
                defaultValue={orderData.comment ?? ""}
                as="textarea"
                onInput={commentInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Intern kommentar</Form.Label>
              <Form.Control
                as="textarea"
                defaultValue={orderData.internalComment ?? ""}
                onInput={internalCommentInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Table responsive striped bordered hover>
              <thead>
                <tr key="-1">
                  <th>
                    {allVerified ? (
                      <CheckAll className="text-success" />
                    ) : (
                      <ExclamationTriangle className="text-danger" />
                    )}
                  </th>
                  <th>Antal</th>
                  <th>Beskrivning</th>
                  <th>Art.nr</th>
                  <th>Inköpspris</th>
                  <th>Försäljningspris</th>
                  <th>Leverantör</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orderData?.articles?.map((article) => (
                  <tr
                    key={article.rowNumber}
                  >
                    <td>
                      {!article.verified ? (
                        <X className="text-danger" />
                      ) : (
                        <Check className="text-success" />
                      )}
                    </td>
                    <td>{article.quantity}</td>
                    <td>{article.description}</td>
                    <td>
                      <Form.Control
                        type="text"
                        value={article.articleNumber}
                        onChange={(e) => articleListArticleNumberHandler(article.id!, e.target.value)}
                        readOnly={orderData.exported || article.verified}
                        disabled={orderData.exported || article.verified}
                      ></Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={article.purchasePrice}
                        onChange={(e) => articleListPurchasePriceHandler(article.id!, parseFloat(e.target.value))}
                        readOnly={orderData.exported || article.verified}
                        disabled={orderData.exported || article.verified}
                      ></Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={article.salesPrice}
                        onChange={(e) => articleListSalesPriceHandler(article.id!, parseFloat(e.target.value))}
                        readOnly={orderData.exported || article.verified}
                        disabled={orderData.exported || article.verified}
                      ></Form.Control>
                    </td>
                    <td>
                      <Form.Select
                        aria-label="Leverantör"
                        value={article.supplierId || ""}
                        onChange={(e) =>
                          articleListSupplierHandler(article.id!, e.target.value)
                        }
                        disabled={orderData.exported || modalArticle.verified}
                      >
                        <option key={0} value={undefined}>
                          - Ingen leverantör vald -
                        </option>
                        {suppliers.map((supplier) => (
                          <option key={supplier.id} value={supplier.id}>
                            {supplier.name}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                    <td
                      onClick={() => openArticleModalHandler(article)}
                      role="button">
                      {!orderData.exported ? <Pen /> : <Lock />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Modal
          size="xl"
          show={showArticleModal}
          onHide={() => closeArticleModalHandler()}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {modalArticle.articleNumber
                ? `${modalArticle.articleNumber} - ${modalArticle.description}`
                : "Ny artikel"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col xs={6} md={1}>
                  <Form.Group className="mb-3">
                    <Form.Label>Antal</Form.Label>
                    <Form.Control
                      type="number"
                      defaultValue={modalArticle.quantity ?? ""}
                      onInput={articleQuantityInputHandler}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={5}>
                  <Form.Group className="mb-3">
                    <Form.Label>Artikelnummer</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.articleNumber ?? ""}
                      onInput={articleArticleNumberInputHandler}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Ersätter</Form.Label>
                    <Form.Control
                      type="text"
                      onInput={articleReplacementInputHandler}
                      defaultValue={modalArticle.replacement}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Leverantör{" "}
                      {isLoadingSuppliers && (
                        <div className="ms-3 d-inline-block">
                          <Spinner variant="primary" size="sm" />
                        </div>
                      )}
                    </Form.Label>
                    <Form.Select
                      aria-label="Leverantör"
                      value={modalArticle.supplierId || ""}
                      onChange={(e) =>
                        articleSupplierInputHandler(e.target.value)
                      }
                      disabled={orderData.exported || modalArticle.verified}
                    >
                      <option key={0} value={undefined}>
                        - Ingen leverantör vald -
                      </option>
                      {suppliers.map((supplier) => (
                        <option key={supplier.id} value={supplier.id}>
                          {supplier.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Artikelstatus{" "}
                      {isLoadingArticleStatuses && (
                        <div className="ms-3 d-inline-block">
                          <Spinner variant="primary" size="sm" />
                        </div>
                      )}
                    </Form.Label>
                    <Form.Select
                      aria-label="Artikelstatus"
                      value={modalArticle.articleStatusId || ""}
                      onChange={(e) =>
                        articleArticleStatusInputHandler(e.target.value)
                      }
                      disabled={orderData.exported || modalArticle.verified}
                    >
                      {articleStatuses.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Externt artikelnummer</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.externalArticleCode ?? ""}
                      onInput={articleExternalArticleCodeInputHandler}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Externt id</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.externalId ?? ""}
                      onInput={articleExternalIdInputHandler}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Beskrivning</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.description ?? ""}
                      onInput={articleDescriptionInputHandler}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Intern kommentar</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.internalComment ?? ""}
                      onInput={articleInternalCommentInputHandler}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>FO-Märkning</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.purchaseOrderComment ?? ""}
                      onInput={articlePurchaseOrderCommentInputHandler}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>IO-Märkning</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.salesOrderComment ?? ""}
                      onInput={articleSalesOrderCommentInputHandler}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Inköpspris</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.purchasePrice ?? ""}
                      onInput={articlePurchasePriceInputHandler}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Försäljningspris</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.salesPrice ?? ""}
                      onInput={articleSalesPriceInputHandler}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Moms</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={modalArticle.vat ?? "0"}
                      onInput={articleVatInputHandler}
                      readOnly={orderData.exported || modalArticle.verified}
                      disabled={orderData.exported || modalArticle.verified}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={6} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Totalt exkl. moms</Form.Label>
                    <Form.Control
                      type="text"
                      value={modalArticle.totalExVat ?? ""}
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Col>
                {/* <Col xs={6} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Totalt inkl. moms</Form.Label>
                    <Form.Control
                      type="text"
                      value={modalArticle.totalIncVat ?? ""}
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Col> */}
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Container>
              {!orderData.exported && (
                <div className="float-start">
                  <Button
                    className="mb-3"
                    variant="secondary"
                    onClick={removeArticleHandler}
                  >
                    Ta bort artikel
                  </Button>
                </div>
              )}

              {!orderData.exported ? (
                <div className="float-end d-inline-flex">
                  <div className="me-3">
                    <Form.Check
                      type="switch"
                      label="Klarmarkerad"
                      className="mt-2"
                      onChange={validatedHandler}
                      checked={modalArticle.verified}
                      disabled={orderData.exported}
                      readOnly={orderData.exported}
                    />
                  </div>

                  <div>
                    <Button
                      className="ms-3 mb-3"
                      variant="primary"
                      onClick={saveArticleInModalHandler}
                    >
                      Spara ändringar
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="text-end">
                  <Button className="mb-3" variant="outline-primary" disabled>
                    Exporterad <Lock />
                  </Button>
                </div>
              )}
            </Container>
          </Modal.Footer>
        </Modal>
        {!orderData.exported && (
          <Button
            variant="outline-primary"
            className="mb-3"
            onClick={addNewArticleHandler}
          >
            Lägg till artikel <Plus />
          </Button>
        )}

        <hr />

        <div>
          <Button
            variant="outline-primary"
            className="mb-3"
            onClick={cancelUpdateOrder}
          >
            Avbryt
          </Button>
          <Button
            variant="primary"
            className="mb-3 float-end"
            onClick={handleUpdateOrder}
            disabled={isWaitingOrderPostResponse}
          >
            Uppdatera order
          </Button>
        </div>

        <Alert key="alert" variant="danger" show={displayError}>
          <p>Något gick fel, kontrollera uppgifterna och försök igen.</p>
        </Alert>
      </Form>
    </div>
  );
};

export default OrderEdit;
