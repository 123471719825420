import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCustomerById,
  putCustomer,
} from "../../app/services/customerService";
import { useAppDispatch } from "../../app/hooks";
import { useAccount, useMsal } from "@azure/msal-react";

import Form from "react-bootstrap/Form";
import { Alert, Breadcrumb, Button, Col, Row } from "react-bootstrap";
import { Customer } from "../../models/responses/customer";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

interface Props {}

const CustomerEdit: FC<Props> = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [displayError, setDisplayError] = useState(false);
  const isWaitingCustomerPutResponse = useSelector(
    (state: RootState) => state.customer.isWaitingCustomerPutResponse
  );
  const customer = useSelector((state: RootState) => state.customer.customer);
  const [customerData, setCustomerData] = useState<Customer>({
    id: "",
    externalId: "",
    name: "",
    address: "",
    email: "",
    phone: "",
    documentFolder: "",
    comment: "",
    createdAt: "",    
    deletedAt: null,
    updatedAt: null,
  });

  useEffect(() => {
    dispatch(getCustomerById(instance, account, customerId));
  }, [account, accounts, dispatch, instance, customerId]);

  useEffect(() => {
    if (customer) {
      setCustomerData({
        id: customer.id,
        externalId: customer.externalId,
        name: customer.name,
        documentFolder: customer.documentFolder,
        address: customer.address,
        email: customer.email,
        phone: customer.phone,
        comment: customer.comment,
        createdAt: customer.createdAt,
        deletedAt: customer.deletedAt,
        updatedAt: customer.updatedAt,
      });
    }
  }, [customer]);

  const documentFolderInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomerData({ ...customerData, documentFolder: e.target.value });
  };

  const externalIdInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerData({ ...customerData, externalId: e.target.value });
  };

  const nameInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerData({ ...customerData, name: e.target.value });
  };

  const addressInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerData({ ...customerData, address: e.target.value });
  };

  const emailInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerData({ ...customerData, email: e.target.value });
  };

  const phoneInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerData({ ...customerData, phone: e.target.value });
  };

  const commentInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerData({ ...customerData, comment: e.target.value });
  };

  const handleUpdateCustomer = () => {
    setDisplayError(false);
    dispatch(putCustomer(instance, account, customerData, onSuccess));
  };

  const onSuccess = (customerId: string | null) => {
    if (customerId === null) {
      setDisplayError(true);
      return;
    }

    setTimeout(() => {
      navigate(`../../${customerId}`, { relative: "path" });
    }, 500);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="/customers">Kunder</Breadcrumb.Item>
        <Breadcrumb.Item active>Uppdatera kund</Breadcrumb.Item>
      </Breadcrumb>
      <hr />
      <Form className="w-3">
        <Row>
          <Col xs={12} md={6}>
          <Form.Group className="mb-3">
              <Form.Label>Kund *</Form.Label>
              <Form.Control
                type="text"
                value={customerData.name}
                onInput={nameInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
          <Form.Group className="mb-3">
              <Form.Label>Kundnummer (AX)</Form.Label>
              <Form.Control
                type="text"
                placeholder="AX0000"
                value={customerData.externalId ?? ""}   
                onInput={externalIdInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Adress</Form.Label>
              <Form.Control
                type="text"
                value={customerData.address ?? ""}
                onInput={addressInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>E-post</Form.Label>
              <Form.Control
                type="email"
                value={customerData.email ?? ""}
                onInput={emailInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Telefon</Form.Label>
              <Form.Control
                type="text"
                value={customerData.phone}
                onInput={phoneInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Dokumentmapp</Form.Label>
              <Form.Control
                type="email"
                value={customerData.documentFolder}
                onInput={documentFolderInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Anteckning</Form.Label>
              <Form.Control
                as="textarea"
                value={customerData.comment ?? ""}
                onInput={commentInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="text-end">
          <Button
            variant="primary"
            className="mb-3"
            onClick={handleUpdateCustomer}
            disabled={
              !(
                customerData.name !== undefined &&
                customerData.name.length > 0 &&
                !isWaitingCustomerPutResponse
              )
            }
          >
            Uppdatera kund
          </Button>
        </div>

        <Alert key="alert" variant="danger" show={displayError}>
          <p>Något gick fel, kontrollera uppgifterna och försök igen.</p>
        </Alert>
      </Form>
    </div>
  );
};

export default CustomerEdit;
