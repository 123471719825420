import axios from "axios";
import { AppDispatch } from "../store";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import {
  setCustomer,
  setCustomerList,
  setIsWaitingCustomerGetResponse,
  setIsWaitingCustomerPostResponse,
  setIsWaitingCustomerPutResponse,
  setQueryCustomerList,
} from "../slices/customerSlice";
import { getApiUrl } from "../helpers/environmentHelper";
import { Customer } from "../../models/responses/customer";
import { Paging } from "../../models/paging";
import { QueryCustomer } from "../../models/responses/queryCustomer";

export const getCustomers =
  (instance: IPublicClientApplication, account: AccountInfo | null) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingCustomerGetResponse(true));
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            console.log("token");
            console.log(response.accessToken);
            if (response) {
              const getData: Customer[] = (
                await axios.get(`${baseUrl}/customers`, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;

              console.log(getData);
              dispatch(setCustomerList({ customers: getData }));
              dispatch(setIsWaitingCustomerGetResponse(false));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingCustomerGetResponse(false));
      console.log(error);
    }
  };

export const queryCustomers =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    page: number,
    pageSize: number
  ) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingCustomerGetResponse(true));
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              const paging: Paging = { page, pageSize };
              const getData: QueryCustomer = (
                await axios.post(`${baseUrl}/customers/query`, paging, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;
              dispatch(setQueryCustomerList({ query: getData }));
              dispatch(setIsWaitingCustomerGetResponse(false));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingCustomerGetResponse(false));
      console.log(error);
    }
  };

export const getCustomerById =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    id: string | undefined
  ) =>
  async (dispatch: AppDispatch) => {
    if (!id) return;
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingCustomerGetResponse(true));

    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            console.log("token");
            console.log(response.accessToken);
            if (response) {
              const getData: Customer = (
                await axios.get(`${baseUrl}/customers/${id}`, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;
              dispatch(setCustomer({ customer: getData }));
              dispatch(setIsWaitingCustomerGetResponse(false));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingCustomerGetResponse(false));
      console.log(error);
    }
  };

export const postCustomer =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    customer: Customer,
    onSuccess: (customerId: string | null) => void
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(setIsWaitingCustomerPostResponse(true));
    const baseUrl = getApiUrl(window.location.hostname, 1);
    let responseId: string | null = null;
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            try {
              if (response) {
                const responseData = await axios.post(
                  `${baseUrl}/customers`,
                  customer,
                  {
                    timeout: 10000,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${response.accessToken}`,
                    },
                  }
                );

                if (responseData.status === 201) {
                  let locationArray =
                    responseData.headers["location"].split("/");
                  responseId = locationArray[locationArray.length - 1];
                }
              }
            } finally {
              onSuccess(responseId);
              dispatch(setIsWaitingCustomerPostResponse(false));
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const putCustomer =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    customer: Customer,
    onSuccess: (customerId: string | null) => void
  ) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingCustomerPutResponse(true));
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            try {
              if (response) {
                const responseData = await axios.put(
                  `${baseUrl}/customers`,
                  customer,
                  {
                    timeout: 10000,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${response.accessToken}`,
                    },
                  }
                );
                if (responseData.status === 204) {
                  onSuccess(customer.id);
                }
              }
            } finally {
              dispatch(setIsWaitingCustomerPutResponse(false));
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
