export interface OrderStatus{
    id: string;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    name: string;
    sortOrder: number;
    showInMenu: boolean;
}

export const defaultOrderStatus: OrderStatus = {
    id: "",
    createdAt: "",
    updatedAt: null,
    deletedAt: null,
    name: "",
    sortOrder: 0,
    showInMenu: false
}