import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MsalUser {
    displayName: string | null;
    givenName: string;
}

const initialState: MsalUser = {
    displayName: null,
    givenName: '',
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<{
            user: MsalUser;
        }>) => {
            state = action.payload.user || initialState;
            return state;
        }        
    },
});

export const {
    setUser,
} = userSlice.actions;

export default userSlice.reducer;