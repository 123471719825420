import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSupplierById } from "../../app/services/supplierService";
import { useAppDispatch } from "../../app/hooks";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

import Form from "react-bootstrap/Form";
import {
  Accordion,
  Breadcrumb,
  Button,
  Col,
  ListGroup,
  Row,
} from "react-bootstrap";
import moment from "moment";

interface Props {}

const SupplierView: FC<Props> = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const dispatch = useAppDispatch();
  moment.locale("sv");
  moment.updateLocale(moment.locale(), { invalidDate: "-" });
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const supplier = useSelector((state: RootState) => state.supplier);

  useEffect(() => {
    dispatch(getSupplierById(instance, account, supplierId));
  }, [account, accounts, dispatch, instance, supplierId]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="/suppliers">Leverantörer</Breadcrumb.Item>
        <Breadcrumb.Item active>{supplier.supplier.name}</Breadcrumb.Item>
      </Breadcrumb>
      <hr />
      <Form>
        <Row>
          <Col xs={12} md={6}>
          <Form.Group className="mb-3">
              <Form.Label>Leverantör</Form.Label>
              <Form.Control
                type="text"
                value={supplier.supplier.name}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
          <Form.Group className="mb-3">
              <Form.Label>Leverantörsnummer (AX)</Form.Label>
              <Form.Control
                type="text"
                value={supplier.supplier.externalId ?? ""}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
          <Form.Group className="mb-3">
              <Form.Label>Bilmärke</Form.Label>
              <Form.Control
                type="text"
                value={supplier.supplier.brand ?? ""}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Procentsats</Form.Label>
              <Form.Control
                type="text"
                value={supplier.supplier.percent ?? ""}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Hämtningsinformation</Form.Label>
              <Form.Control
                type="text"
                value={supplier.supplier.pickupName ?? ""}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Hämtningsaddress</Form.Label>
              <Form.Control
                type="text"
                value={supplier.supplier.pickupAddress ?? ""}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Adress</Form.Label>
              <Form.Control
                type="text"
                value={supplier.supplier.address}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>E-post</Form.Label>
              <Form.Control
                type="email"
                value={supplier.supplier.email}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Telefonnummer</Form.Label>
              <Form.Control
                type="text"
                value={supplier.supplier.phone}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Valuta</Form.Label>
              <Form.Control
                type="text"
                value={supplier.supplier.currency}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Anteckning</Form.Label>
              <Form.Control
                as="textarea"
                value={supplier.supplier.comment ?? ""}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Row>
        <div className="text-end">
          <Button variant="primary" href={`/suppliers/${supplierId}/edit`}>
            Redigera
          </Button>
        </div>
      </Row>

      <div className="mt-3">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Historik</Accordion.Header>
            <Accordion.Body>
              <ListGroup as="ul">
                {supplier?.supplier?.supplierHistory?.map((history) => (
                  <ListGroup.Item as="li">
                    {(() => {
                      switch (history.action.toLowerCase()) {
                        case "add":
                          return <span>Skapades av </span>;
                        case "update":
                          return <span>Uppdaterades av </span>;
                        default:
                          return <span>-</span>;
                      }
                    })()}
                    <span>{history.userName} </span>
                    <span>
                      {" "}
                      den {moment.utc(history.createdAt).local().format("LLL")}
                    </span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default SupplierView;
