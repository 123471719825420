import { AppDispatch } from "../store";
// import { callMsGraph } from "../utils/MsGraphApiCall";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { setUser } from "../slices/userSlice";

export const getUser =
  (
    instance: IPublicClientApplication,    
    account: AccountInfo | null
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["User.Read"],
            account: account,
          })
          .then((response) => {
            if (response) {                           
                dispatch(
                  setUser({
                    user: {
                      displayName: account.username,
                      givenName: account.name ?? 'Error'
                    },
                  })
                );              
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };