import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ArticleStatus, defaultArticleStatus } from "../../models/responses/articleStatus";

export interface ArticleStatusState {
    articleStatus: ArticleStatus;
    articleStatusList: ArticleStatus[];
    isWaitingArticleStatusGetResponse: boolean;
}

const initialState: ArticleStatusState = {
    articleStatusList: [],
    articleStatus: { ...defaultArticleStatus },
    isWaitingArticleStatusGetResponse: false
};

const articleStatusSlice = createSlice({
    name: 'articleStatus',
    initialState,
    reducers: {
        setArticleStatusList: (state, action: PayloadAction<{
            setArticleStatusList: ArticleStatus[];
        }>) => {
            state.articleStatusList = action.payload.setArticleStatusList || initialState;
            return state;
        },
        setArticleStatus: (state, action: PayloadAction<{
            articleStatus: ArticleStatus;
        }>) => {
            state.articleStatus = action.payload.articleStatus || initialState;
            return state;
        },
        setIsWaitingArticleStatusGetResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingArticleStatusGetResponse = action.payload;
            return state;
        },
    },
});

export const {
    setArticleStatus,
    setArticleStatusList,
    setIsWaitingArticleStatusGetResponse,
} = articleStatusSlice.actions;

export default articleStatusSlice.reducer;