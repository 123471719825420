import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import RootLayout from "./rootLayout";
import Home from "./pages/sign-in/home";
import Dashboard from "./pages/sign-in/dashboard";
import Suppliers from "./pages/suppliers";
import Customers from "./pages/customers";
import SupplierView from "./pages/suppliers/view";
import SupplierAdd from "./pages/suppliers/add";
import SupplierEdit from "./pages/suppliers/edit";
import CustomerView from "./pages/customers/view";
import CustomerAdd from "./pages/customers/add";
import CustomerEdit from "./pages/customers/edit";
import Orders from "./pages/orders";
import OrderView from "./pages/orders/view";
import OrderAdd from "./pages/orders/add";
import OrderEdit from "./pages/orders/edit";

export function createRoutes() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/suppliers">
          <Route index={true} element={<Suppliers />}></Route>
          <Route index={false} path=":supplierId" element={<SupplierView />}></Route>
          <Route index={false} path="add" element={<SupplierAdd />}></Route>
          <Route index={false} path=":supplierId/edit" element={<SupplierEdit />}></Route>
        </Route>
        <Route path="/customers" >
          <Route index={true} element={<Customers />}></Route>
          <Route index={false} path=":customerId" element={<CustomerView />}></Route>
          <Route index={false} path="add" element={<CustomerAdd />}></Route>
          <Route index={false} path=":customerId/edit" element={<CustomerEdit />}></Route>
        </Route>
        <Route path="/orderstatus" >
          <Route index={true} path=":orderStatusId" element={<Orders />}></Route>
          <Route index={false} path=":orderStatusId/orders/view/:orderId" element={<OrderView />}></Route>
          <Route index={false} path=":orderStatusId/orders/add" element={<OrderAdd />}></Route>
          <Route index={false} path=":orderStatusId/orders/edit/:orderId" element={<OrderEdit />}></Route>
        </Route>
      </Route>
    )
  );
}
