import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultOrder, Order } from "../../models/responses/order";
import { Article } from "../../models/responses/article";
import { defaultOrderUser, OrderUser } from "../../models/responses/orderUser";
import { QueryOrder } from "../../models/responses/queryOrder";

export interface OrderState {
    orderList: Order[];
    order: Order;
    orderUser: OrderUser;
    ordersByUserId: Order[];
    isWaitingOrderPostResponse: boolean;
    isWaitingOrderPutResponse: boolean;
    isWaitingOrderGetResponse: boolean;
    queryOrderList: Order[] | null;
    totalQueryOrder: number;
}

const initialState: OrderState = {
    orderList: [],
    order: { ...defaultOrder },
    orderUser: { ...defaultOrderUser },
    ordersByUserId: [],
    isWaitingOrderPostResponse: false,
    isWaitingOrderPutResponse: false,
    isWaitingOrderGetResponse: false,
    queryOrderList: null,
    totalQueryOrder: 0,
};

const calculateArticleTotals = (articles: Article[] | null) : Article[] | null => {
    if (!articles)
        return null;

    return articles.map(article => ({
        ...article,
        salesPrice: parseFloat(interpretAsDecimal(article.salesPrice).toFixed(2)),
        purchasePrice: parseFloat(interpretAsDecimal(article.purchasePrice).toFixed(2)),
        quantity: parseFloat(interpretAsDecimal(article.quantity).toFixed(2)),
        vat: parseFloat(interpretAsDecimal(article.vat).toFixed(2)),
        totalExVat: parseFloat((interpretAsDecimal(article.salesPrice) * parseFloat(interpretAsDecimal(article.quantity).toFixed(2))).toFixed(2)),
        totalIncVat: parseFloat((interpretAsDecimal(article.salesPrice) * parseFloat(interpretAsDecimal(article.quantity).toFixed(2)) + parseFloat(interpretAsDecimal(article.vat).toFixed(2))).toFixed(2))
    }));
};

const interpretAsDecimal = (input: number) : number => {
    var value = input.toString();
    var beforeComma = value.slice(0, value.length - 2);
    var afterComma = value.slice(value.length - 2, value.length);

    return parseFloat(`${beforeComma}.${afterComma}`);
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrderList: (state, action: PayloadAction<{ orders: Order[] }>) => {
            state.orderList = action.payload.orders.map(order => ({
                ...order,
                articles: calculateArticleTotals(order.articles)
            }));
        },
        setOrder: (state, action: PayloadAction<{ order: Order }>) => {
            state.order = {                
                ...action.payload.order,
                articles: calculateArticleTotals(action.payload.order.articles)
            };
        },
        setOrderUser: (state, action: PayloadAction<{ orderUser: OrderUser }>) => {
            state.orderUser = {
                ...action.payload.orderUser
            };
        },
        setOrderListByUserId: (state, action: PayloadAction<{ orders: Order[] }>) => {
            state.ordersByUserId = action.payload.orders.map(order => ({
                ...order,
                articles: calculateArticleTotals(order.articles)
            }));
        },
        setIsWaitingOrderPostResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingOrderPostResponse = action.payload;
        },
        setIsWaitingOrderPutResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingOrderPutResponse = action.payload;
        },
        setIsWaitingOrderGetResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingOrderGetResponse = action.payload;
        },
        setQueryOrderList: (state, action: PayloadAction<{
            query: QueryOrder;
        }>) => {
            state.queryOrderList = action.payload.query.orders || initialState.queryOrderList;
            state.totalQueryOrder = action.payload.query.totalCount || initialState.totalQueryOrder;
            return state;
        }
    },
});

export const {
    setOrderList,
    setOrder,
    setIsWaitingOrderPostResponse,
    setIsWaitingOrderPutResponse,
    setIsWaitingOrderGetResponse,
    setQueryOrderList,
    setOrderUser,
    setOrderListByUserId
} = orderSlice.actions;

export default orderSlice.reducer;