import axios from "axios";
import { AppDispatch } from "../store";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import {
  reloadSupplierList,
  runningSupplierImport,
  setIsWaitingSupplierGetResponse,
  setIsWaitingSupplierPostResponse,
  setIsWaitingSupplierPutResponse,
  setQuerySupplierList,
  setSupplier,
  setSupplierList,
} from "../slices/supplierSlice";
import { getApiUrl } from "../helpers/environmentHelper";
import { Supplier } from "../../models/responses/supplier";
import { Paging } from "../../models/paging";
import { QuerySupplier } from "../../models/responses/querySupplier";

export const getSuppliers =
  (instance: IPublicClientApplication, account: AccountInfo | null) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingSupplierGetResponse(true));

    try {
      dispatch(reloadSupplierList(false));

      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              const getData: Supplier[] = (
                await axios.get(`${baseUrl}/suppliers`, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;

              dispatch(setSupplierList({ suppliers: getData }));
              dispatch(setIsWaitingSupplierGetResponse(false));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingSupplierGetResponse(false));
      console.log(error);
    }
  };

export const querySuppliers =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    page: number,
    pageSize: number
  ) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingSupplierGetResponse(true));
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              const paging: Paging = { page, pageSize };
              const getData: QuerySupplier = (
                await axios.post(`${baseUrl}/suppliers/query`, paging, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;
              dispatch(setQuerySupplierList({ query: getData }));
              dispatch(setIsWaitingSupplierGetResponse(false));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingSupplierGetResponse(false));
      console.log(error);
    }
  };

export const getSupplierById =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    id: string | undefined
  ) =>
  async (dispatch: AppDispatch) => {
    if (!id) return;

    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingSupplierGetResponse(true));

    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              const getData: Supplier = (
                await axios.get(`${baseUrl}/suppliers/${id}`, {
                  timeout: 10000,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                })
              ).data;

              console.log(getData);
              dispatch(setSupplier({ supplier: getData }));
              dispatch(setIsWaitingSupplierGetResponse(false));
            }
          });
      }
    } catch (error) {
      dispatch(setIsWaitingSupplierGetResponse(false));
      console.log(error);
    }
  };

export const postSupplier =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    supplier: Supplier,
    onSuccess: (supplierId: string | null) => void
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(setIsWaitingSupplierPostResponse(true));
    const baseUrl = getApiUrl(window.location.hostname, 1);
    let responseId: string | null = null;

    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            try {
              if (response) {
                const responseData = await axios.post(
                  `${baseUrl}/suppliers`,
                  supplier,
                  {
                    timeout: 10000,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${response.accessToken}`,
                    },
                  }
                );

                if (responseData.status === 201) {
                  let locationArray =
                    responseData.headers["location"].split("/");
                  responseId = locationArray[locationArray.length - 1];
                }
              }
            } finally {
              onSuccess(responseId);
              dispatch(setIsWaitingSupplierPostResponse(false));
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const putSupplier =
  (
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    supplier: Supplier,
    onSuccess: (supplierId: string | null) => void
  ) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);
    dispatch(setIsWaitingSupplierPutResponse(true));
    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            try {
              if (response) {
                const responseData = await axios.put(
                  `${baseUrl}/suppliers`,
                  supplier,
                  {
                    timeout: 10000,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${response.accessToken}`,
                    },
                  }
                );

                if (responseData.status === 204) {
                  onSuccess(supplier.id);
                }
              }
            } finally {
              dispatch(setIsWaitingSupplierPutResponse(false));
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const importSuppliers =
  (instance: IPublicClientApplication, account: AccountInfo | null) =>
  async (dispatch: AppDispatch) => {
    const baseUrl = getApiUrl(window.location.hostname, 1);

    dispatch(runningSupplierImport(true));

    try {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["api://fc6b72c0-fa2c-4b84-9d5c-dfa2b1cfffcc/Read"],
            account: account,
            authority:
              "https://login.microsoftonline.com/a891c7dd-26b3-4449-8f2c-3fb8ee16ef12",
          })
          .then(async (response) => {
            if (response) {
              console.log("request import");
              await axios.get(`${baseUrl}/suppliers/import`, {
                timeout: 60000,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${response.accessToken}`,
                },
              });

              console.log("imported");
              dispatch(reloadSupplierList(true));
              dispatch(runningSupplierImport(false));
            }
          });
      }
    } catch (error) {
      console.log(error);
      dispatch(runningSupplierImport(false));
    }
  };
