import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSupplierById,
  putSupplier,
} from "../../app/services/supplierService";
import { useAppDispatch } from "../../app/hooks";
import { useAccount, useMsal } from "@azure/msal-react";

import Form from "react-bootstrap/Form";
import { Alert, Breadcrumb, Button, Col, Row } from "react-bootstrap";
import { Supplier } from "../../models/responses/supplier";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

interface Props { }

const SupplierEdit: FC<Props> = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [displayError, setDisplayError] = useState(false);
  const isWaitingSupplierPutResponse = useSelector(
    (state: RootState) => state.supplier.isWaitingSupplierPutResponse
  );
  const supplier = useSelector((state: RootState) => state.supplier.supplier);
  const [supplierData, setSupplierData] = useState<Supplier>({
    id: "",
    externalId: "",
    name: "",
    brand: "",
    percent: "",
    pickupName: "",
    pickupAddress: "",
    address: "",
    email: "",
    phone: "",
    comment: "",
    createdAt: "",
    currency: "",
    deletedAt: null,
    updatedAt: null,
    supplierHistory: null,
  });

  useEffect(() => {
    dispatch(getSupplierById(instance, account, supplierId));
  }, [account, accounts, dispatch, instance, supplierId]);

  useEffect(() => {
    if (supplier) {
      setSupplierData({
        id: supplier.id,
        externalId: supplier.externalId,
        name: supplier.name,
        brand: supplier.brand,
        percent: supplier.percent,
        pickupName: supplier.pickupName,
        pickupAddress: supplier.pickupAddress,
        address: supplier.address,
        email: supplier.email,
        phone: supplier.phone,
        comment: supplier.comment,
        currency: supplier.currency,
        createdAt: supplier.createdAt,
        deletedAt: supplier.deletedAt,
        updatedAt: supplier.updatedAt,
        supplierHistory: supplier.supplierHistory,
      });
    }
  }, [supplier]);

  const brandInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupplierData({ ...supplierData, brand: e.target.value });
  };

  const nameInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupplierData({ ...supplierData, name: e.target.value });
  };

  const percentageInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupplierData({ ...supplierData, percent: e.target.value });
  };

  const pickupNameInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupplierData({ ...supplierData, pickupName: e.target.value });
  };

  const pickupAddressInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSupplierData({ ...supplierData, pickupAddress: e.target.value });
  };

  const addressInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupplierData({ ...supplierData, address: e.target.value });
  };

  const emailInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupplierData({ ...supplierData, email: e.target.value });
  };

  const phoneInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupplierData({ ...supplierData, phone: e.target.value });
  };

  const commentInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupplierData({ ...supplierData, comment: e.target.value });
  };

  const currencyInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupplierData({ ...supplierData, currency: e.target.value });
  };

  const handleUpdateSupplier = () => {
    setDisplayError(false);
    dispatch(putSupplier(instance, account, supplierData, onSuccess));
  };

  const onSuccess = (supplierId: string | null) => {
    if (supplierId === null) {
      setDisplayError(true);
      return;
    }

    setTimeout(() => {
      navigate(`../../${supplierId}`, { relative: "path" });
    }, 500);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="/suppliers">Leverantörer</Breadcrumb.Item>
        <Breadcrumb.Item active>Uppdatera leverantör</Breadcrumb.Item>
      </Breadcrumb>
      <hr />
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Leverantör *</Form.Label>
              <Form.Control
                type="text"
                defaultValue={supplierData.name ?? ""}
                onInput={nameInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Leverantörsnummer (AX)</Form.Label>
              <Form.Control
                type="text"
                defaultValue={supplierData.externalId ?? ""}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Bilmärke</Form.Label>
              <Form.Control
                type="text"
                defaultValue={supplierData.brand ?? ""}
                onInput={brandInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Procentsats</Form.Label>
              <Form.Control
                type="text"
                defaultValue={supplierData.percent ?? ""}
                onInput={percentageInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Hämtningsinformation</Form.Label>
              <Form.Control
                type="text"
                defaultValue={supplierData.pickupName ?? ""}
                onInput={pickupNameInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Hämtningsaddress</Form.Label>
              <Form.Control
                type="text"
                defaultValue={supplierData.pickupAddress ?? ""}
                onInput={pickupAddressInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Adress</Form.Label>
              <Form.Control
                type="text"
                defaultValue={supplierData.address}
                onInput={addressInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>E-post</Form.Label>
              <Form.Control
                type="email"
                defaultValue={supplierData.email}
                onInput={emailInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Telefonnummer</Form.Label>
              <Form.Control
                type="text"
                defaultValue={supplierData.phone}
                onInput={phoneInputHandler}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Valuta</Form.Label>
              <Form.Control
                type="text"
                defaultValue={supplierData.currency}
                onInput={currencyInputHandler}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Anteckning</Form.Label>
              <Form.Control
                as="textarea"
                defaultValue={supplierData.comment ?? ""}
                onInput={commentInputHandler}

              />
            </Form.Group>
          </Col>
        </Row>

        <div className="text-end">
          <Button
            variant="primary"
            className="mb-3"
            onClick={handleUpdateSupplier}
            disabled={
              !(
                supplierData.name !== undefined &&
                supplierData.name.length > 0 &&
                !isWaitingSupplierPutResponse
              )
            }
          >
            Uppdatera leverantör
          </Button>
        </div>

        <Alert key="alert" variant="danger" show={displayError}>
          <p>Något gick fel, kontrollera uppgifterna och försök igen.</p>
        </Alert>
      </Form>
    </div>
  );
};

export default SupplierEdit;
