import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Customer, defaultCustomer } from "../../models/responses/customer";
import { QueryCustomer } from "../../models/responses/queryCustomer";

export interface CustomerState {
    customerList: Customer[];
    customer: Customer;
    isWaitingCustomerPostResponse: boolean;
    isWaitingCustomerPutResponse: boolean;
    queryCustomerList: Customer[] | null;
    totalQueryCustomer: number;
    isWaitingCustomerGetResponse: boolean;
}

const initialState: CustomerState = {
    customerList: [],
    customer: { ...defaultCustomer },
    isWaitingCustomerPostResponse: false,
    isWaitingCustomerPutResponse: false,
    isWaitingCustomerGetResponse: false,
    queryCustomerList: null,
    totalQueryCustomer: 0,
};

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCustomerList: (state, action: PayloadAction<{
            customers: Customer[];
        }>) => {
            state.customerList = action.payload.customers || initialState;
            return state;
        },
        setCustomer: (state, action: PayloadAction<{
            customer: Customer;
        }>) => {
            state.customer = action.payload.customer || initialState;
            return state;
        },
        setIsWaitingCustomerPostResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingCustomerPostResponse = action.payload;
        },
        setIsWaitingCustomerPutResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingCustomerPutResponse = action.payload;
        },
        setIsWaitingCustomerGetResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingCustomerGetResponse = action.payload;
        },
        setQueryCustomerList: (state, action: PayloadAction<{
            query: QueryCustomer;
        }>) => {
            state.queryCustomerList = action.payload.query.customers || initialState.queryCustomerList;
            state.totalQueryCustomer = action.payload.query.totalCount || initialState.totalQueryCustomer;
            return state;
        }
    },
});

export const {
    setCustomerList,
    setCustomer,
    setIsWaitingCustomerPostResponse,
    setIsWaitingCustomerPutResponse,
    setIsWaitingCustomerGetResponse,
    setQueryCustomerList,
} = customerSlice.actions;

export default customerSlice.reducer;