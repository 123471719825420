import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';
import supplierSlice from './slices/supplierSlice';
import customerSlice from './slices/customerSlice';
import orderStatusSlice from './slices/orderStatusSlice';
import orderSlice from './slices/orderSlice';
import orderTypeSlice from './slices/orderTypeSlice';
import articleStatusSlice from './slices/articleStatusSlice';

export const store = configureStore({
  reducer: {
    articleStatus: articleStatusSlice,
    user: userSlice,
    supplier: supplierSlice,
    customer: customerSlice,
    orderStatus: orderStatusSlice,
    order: orderSlice,
    orderTypes: orderTypeSlice    
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
