import { FC, useEffect, useState } from "react";
import { getOrders, queryOrders } from "../../app/services/orderService";
import { useAppDispatch } from "../../app/hooks";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Order } from "../../models/responses/order";
import { Col, Pagination, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getOrderStatusById } from "../../app/services/orderStatusService";
import { OrderStatus } from "../../models/responses/orderStatus";
import { Check, Eye, Plus } from "react-bootstrap-icons";

interface Props { }

const Orders: FC<Props> = () => {
  moment.locale("sv");
  moment.updateLocale(moment.locale(), { invalidDate: "-" });
  const navigate = useNavigate();

  const { orderStatusId } = useParams<{ orderStatusId: string }>();

  const loadingOrders: boolean = useSelector(
    (state: RootState) => state.order.isWaitingOrderGetResponse
  );

  const orders: Order[] = useSelector(
    (state: RootState) => state.order.queryOrderList || []
  );

  const orderStatus: OrderStatus = useSelector(
    (state: RootState) => state.orderStatus.orderStatus
  );

  const dispatch = useAppDispatch();

  const { instance, accounts } = useMsal();

  const account = useAccount(accounts[0] || {});

  const [page, setPage] = useState(1);
  const [pageSize] = useState(50);
  const total = useSelector((state: RootState) => state.order.totalQueryOrder);
  const totalPages = Math.ceil((total || 0) / pageSize);

  useEffect(() => {
    dispatch(queryOrders(instance, account, orderStatusId, page, pageSize));
  }, [account, accounts, dispatch, instance, orderStatusId, page, pageSize]);

  useEffect(() => {
    dispatch(getOrderStatusById(instance, account, orderStatusId));
  }, [account, accounts, dispatch, instance, orderStatusId]);

  const openOrderHandler = (order: Order) => {
    navigate(`/orderStatus/${order.orderStatusId}/orders/view/${order.id}`, {
      relative: "path",
    });
  };

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <h1>{orderStatus.name}</h1>
        </Col>
        <Col>
          <Button
            className="float-end mt-2"
            variant="outline-primary"
            href={`/orderStatus/${orderStatusId}/orders/add`}
          >
            Lägg till <Plus />
          </Button>
        </Col>
      </Row>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Kund</th>
            <th>Inkommen</th>
            <th>Reg.nr</th>
            <th>AO-nummer</th>
            <th>Redigeras av</th>
            <th>Exporterad</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr
              key={order.id}
              role="button"
              onClick={() => openOrderHandler(order)}
            >
              <td>{order.customer?.name}</td>
              <td>{moment(order.arriveDate).format("LL")}</td>
              <td>{order.registrationNumber}</td>
              <td>{order.aoNumber}</td>
              <td>{order.userName}</td>
              <td>
                {order.exported && (
                  <Check className="text-success" />
                )}
              </td>
              <td align="center">
                <Eye />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {loadingOrders && (
        <>
          <Row className="mb-3">
            <Col className="text-center">
              <Spinner variant="primary" />
            </Col>
          </Row>
        </>
      )}
      <Pagination className="justify-content-center">
        <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
        <Pagination.Prev
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        />
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === page}
            onClick={() => setPage(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
        />
        <Pagination.Last
          onClick={() => setPage(totalPages)}
          disabled={page === totalPages}
        />
      </Pagination>
    </div>
  );
};

export default Orders;
