import { SupplierHistory } from "./supplierHistory";

export interface Supplier {
    id: string;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    name: string;
    externalId: string | null;
    brand: string | null;
    percent: string | null;
    pickupName: string | null;
    pickupAddress: string | null;
    address: string;
    email: string;
    phone: string;
    comment: string | null;
    currency: string;
    supplierHistory: SupplierHistory[] | null;
}

export const defaultSupplier: Supplier = {
    id: '',
    createdAt: '',
    updatedAt: null,
    deletedAt: null,
    name: '',
    externalId: null,
    brand: null,
    percent: null,
    pickupName: null,
    pickupAddress: null,
    address: '',
    email: '',
    phone: '',
    comment: null,
    currency: '',
    supplierHistory: null,
};