import React from "react";
import { RouterProvider } from "react-router-dom";
import { createRoutes } from "./routes";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

const routes = createRoutes();

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

function App() {
  const authRequest = {
    scopes: ["openid", "profile"],
  };

  return (
    <div>
      {/* {false ? <RouterProvider router={routes} /> : <SignIn />} */}
      <AuthenticatedTemplate>
        <RouterProvider router={routes} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          loadingComponent={LoadingComponent}
        >
          <div>USER IS NOT SIGNED IN!</div>
        </MsalAuthenticationTemplate>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
